import Header from "../mobileSections/Header";
import Footer from "../mobileSections/Footer";
import HomeMain from "../mobileSections/HomeMain";
import 'aos/dist/aos.css';


function About() {
    // Define mobile-specific styles
    const rootStyle = {
        width: "100%",
        height: "100%",
        overflowX: "hidden",


    };

    const masterPageStyle = {
        maxWidth: "480px !important",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",

    };


    return (
        <div  className="" style={rootStyle}>
            <div  className=" " style={masterPageStyle}>
                <Header />
                <HomeMain />
                <Footer />
            </div>
        </div>
    );
}

export default About;
