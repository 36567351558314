import Header from "../sections/Header";
import Footer from "../sections/Footer";
import AboutMain from "../sections/AboutMain";
import '../static/css/about.css'
function About() {
    return (
        <div id="site-root" className="site-root">
            <div id="masterPage" className="mesh-layout remove-wrappers masterPage css-editing-scope">
                <Header/>
                <AboutMain/>
                <Footer/>
            </div>
        </div>
    );
}

export default About;
