import WhiteLogo from '../static/img/White Logo.png';

function AboutMain() {
    const mainStyle = {
        width: "100%",
        height: "100%",
        maxWidth: "480px",
        margin: "0 auto",
        boxSizing: "border-box",
        paddingTop: "80px",  // Add padding equal to header height
    };

    const greenSectionStyle = {
        backgroundColor: "#228b22",
        color: "#ffffff",
        padding: "40px 40px",
        textAlign: "center",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    };

    const headerStyle = {
        fontSize: "24px", // Adjusted for mobile
        lineHeight: "1.4em",
        fontFamily: "libre baskerville, serif",
        fontWeight: "bold",
        zIndex: 1,
    };

    const logoStyle = {
        width: "60%",        // Set the width of the logo
        opacity: "0.3",       // Make the logo subtle
        position: "absolute", // Position the logo absolutely in the center
        top: "50%",           // Center vertically
        left: "50%",          // Center horizontally
        transform: "translate(-50%, -50%)", // Move back by half width and height
        zIndex: 0,

    };

    const whiteSectionStyle = {
        backgroundColor: "#ffffff",
        padding: "30px 40px",
        textAlign: "center",
    };

    const subHeaderStyle = {
        fontSize: "20px", // Adjusted for mobile
        lineHeight: "1.4em",
        color: "#313f4f",
        fontFamily: "libre baskerville, serif",
        fontWeight: "700",
        letterSpacing: "0.01em",
        marginTop: "15px",
    };

    const paragraphStyle = {
        fontSize: "16px", // Smaller for mobile
        lineHeight: "1.4em",
        color: "#313f4f",
        textAlign: "center",
        padding: "20px 10px",
    };

    return (
        <main id="PAGES_CONTAINER" style={mainStyle}>
            {/* Green Section */}
            <div style={greenSectionStyle}>
                <h4 style={headerStyle}>
                    Surrounding ourselves with innovators and challengers is key to Verde Global's philosophy and success.
                </h4>
                <img src={WhiteLogo} alt="Tree Logo" style={logoStyle} />
                <div style={{ width: "25px", borderColor: "#c2b280", margin: "20px auto", zIndex: 1,borderRadius: '3px',height: '0px',borderWidth:"1.5px" ,borderStyle: "solid" }} />
            </div>

            {/* White Section */}
            <div style={whiteSectionStyle}>
                <h5 style={subHeaderStyle}>
                    We’ve assembled a team of sustainability-focused entrepreneurs and advisors to support our mission.
                </h5>
                <p style={paragraphStyle}>
                    We engage our investors at every stage, from initial screening to post-investment growth, ensuring we remain a valuable asset.
                </p>
            </div>
        </main>
    );
}

export default AboutMain;
