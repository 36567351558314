import React, { useState } from 'react';

function ContactMain() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        companyName: '',
        cityState: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const generateMailtoLink = () => {
        const mailtoLink = `mailto:Info@verdeglobal.co?subject=${encodeURIComponent(formData.subject)}&body=Name: ${encodeURIComponent(formData.name)}%0D%0AEmail: ${encodeURIComponent(formData.email)}%0D%0ACompany Name: ${encodeURIComponent(formData.companyName)}%0D%0ACity, State: ${encodeURIComponent(formData.cityState)}%0D%0A%0D%0AMessage: ${encodeURIComponent(formData.message)}`;
        return mailtoLink;
    };

    return (

        <main id="PAGES_CONTAINER" className="PAGES_CONTAINER" tabIndex="-1" data-main-content="true">
            <div id="SITE_PAGES" className="JsJXaX SITE_PAGES">
                <div id="SITE_PAGES_TRANSITION_GROUP" className="AnQkDU">
                    <div id="a4bba" className="dBAkHi a4bba">
                        <div className="PFkO7r wixui-page" data-testid="page-bg"></div>
                        <div className="HT5ybB">
                            <div id="Containera4bba" className="Containera4bba SPY_vo">
                                <div data-mesh-id="Containera4bbainlineContent" data-testid="inline-content"
                                     className="">
                                    <div data-mesh-id="Containera4bbainlineContent-gridContainer"
                                         data-testid="mesh-container-content">
                                        <section id="comp-lh3d5tpf" tabIndex="-1"
                                                 className="Oqnisf comp-lh3d5tpf wixui-section"
                                                 data-block-level-container="ClassicSection">
                                            <div id="bgLayers_comp-lh3d5tpf" data-hook="bgLayers"
                                                 data-motion-part="BG_LAYER" className="MW5IWV">
                                                <div data-testid="colorUnderlay" className="LWbAav Kv1aVt">
                                                </div>
                                                <div id="bgMedia_comp-lh3d5tpf" data-motion-part="BG_MEDIA"
                                                     className="VgO9Yg"></div>
                                            </div>
                                            <div data-mesh-id="comp-lh3d5tpfinlineContent"
                                                 data-testid="inline-content" className="">
                                                <div data-mesh-id="comp-lh3d5tpfinlineContent-gridContainer"
                                                     data-testid="mesh-container-content">
                                                    <section id="comp-jxm1v3uu"
                                                             className="comp-jxm1v3uu CohWsy wixui-column-strip">
                                                        <div id="bgLayers_comp-jxm1v3uu"
                                                             data-hook="bgLayers" data-motion-part="BG_LAYER"
                                                             className="if7Vw2">
                                                            <div data-testid="colorUnderlay"
                                                                 className="tcElKx i1tH8h"></div>
                                                            <div id="bgMedia_comp-jxm1v3uu"
                                                                 data-motion-part="BG_MEDIA" className="wG8dni">
                                                            </div>
                                                        </div>
                                                        <div data-testid="columns" className="V5AUxf">
                                                            <div id="comp-jxm1v3wt"
                                                                 className="comp-jxm1v3wt YzqVVZ wixui-column-strip__column">
                                                                <div id="bgLayers_comp-jxm1v3wt"
                                                                     data-hook="bgLayers"
                                                                     data-motion-part="BG_LAYER"
                                                                     className="MW5IWV">
                                                                    <div data-testid="colorUnderlay"
                                                                         className="LWbAav Kv1aVt"></div>
                                                                    <div id="bgMedia_comp-jxm1v3wt"
                                                                         data-motion-part="BG_MEDIA"
                                                                         className="VgO9Yg"></div>
                                                                </div>
                                                                <div data-mesh-id="comp-jxm1v3wtinlineContent"
                                                                     data-testid="inline-content" className="">
                                                                    <div
                                                                        data-mesh-id="comp-jxm1v3wtinlineContent-gridContainer"
                                                                        data-testid="mesh-container-content">
                                                                        <div id="comp-jxm1x4np"
                                                                             className="HcOXKn SxM0TO QxJLC3 lq2cno comp-jxm1x4np wixui-rich-text"
                                                                             data-testid="richTextElement">
                                                                            <h4 className="font_4 wixui-rich-text__text"
                                                                                style={{fontSize: '60px', lineHeight: '1.4em', textAlign: 'center'}}>
                                                                                            <span
                                                                                                style={{fontSize: '60px'}}
                                                                                                className="wixui-rich-text__text"><span
                                                                                                style={{fontWeight: 'bold'}}
                                                                                                className="wixui-rich-text__text"><span
                                                                                                style={{fontFamily: 'libre baskerville,serif'}}
                                                                                                className="wixui-rich-text__text"><span
                                                                                                style={{color: '#313F4F'}}
                                                                                                className="wixui-rich-text__text">Contact
                                                                                                            us</span></span></span></span>
                                                                            </h4>
                                                                        </div>
                                                                        <div id="comp-jxm1v3x6"
                                                                             className="HcOXKn SxM0TO QxJLC3 lq2cno comp-jxm1v3x6 wixui-rich-text"
                                                                             data-testid="richTextElement">
                                                                            <p className="font_8 wixui-rich-text__text"
                                                                               style={{fontSize: '20px', lineHeight: '1.4em', textAlign: 'center'}}>
                                                                                            <span
                                                                                                style={{fontSize: '20px'}}
                                                                                                className="wixui-rich-text__text"><span
                                                                                                style={{fontFamily: 'proxima-n-w01-reg,sans-serif'}}
                                                                                                className="wixui-rich-text__text"><span
                                                                                                style={{color: '#313F4F'}}
                                                                                                className="wixui-rich-text__text">For
                                                                                                        more&nbsp;information
                                                                                                        please&nbsp;</span></span></span><span
                                                                                style={{fontSize: '20px'}}
                                                                                className="wixui-rich-text__text"><span
                                                                                style={{fontFamily: 'proxima-n-w01-reg,sans-serif'}}
                                                                                className="wixui-rich-text__text"><span
                                                                                style={{color: '#313F4F'}}
                                                                                className="wixui-rich-text__text">submit
                                                                                                        the form
                                                                                                        below.</span></span></span>
                                                                            </p>
                                                                        </div>
                                                                        <div id="comp-jxm1v3xe"
                                                                             className="bkIuWA comp-jxm1v3xe">
                                                                            <div
                                                                                data-mesh-id="comp-jxm1v3xeinlineContent"
                                                                                data-testid="inline-content"
                                                                                className="">
                                                                                <div
                                                                                    data-mesh-id="comp-jxm1v3xeinlineContent-gridContainer"
                                                                                    data-testid="mesh-container-content">
                                                                                    <form id="comp-kei6uey3"
                                                                                          className="JVi7i2 comp-kei6uey3 wixui-form">
                                                                                        <div
                                                                                            data-mesh-id="comp-kei6uey3inlineContent"
                                                                                            data-testid="inline-content"
                                                                                            className="">
                                                                                            <div
                                                                                                data-mesh-id="comp-kei6uey3inlineContent-gridContainer"
                                                                                                data-testid="mesh-container-content">
                                                                                                <div id="comp-kei6uez9"
                                                                                                     className="MpKiNN comp-kei6uez9 wixui-text-input lPl_oN">
                                                                                                    <div
                                                                                                        className="pUnTVX">
                                                                                                        <input
                                                                                                            name="name"
                                                                                                            id="input_comp-kei6uez9"
                                                                                                            className="KvoMHf has-custom-focus wixui-text-input__input"
                                                                                                            type="text"
                                                                                                            placeholder="Name *"
                                                                                                            value={formData.name}
                                                                                                            onChange={handleChange}
                                                                                                            required />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div id="comp-kei6uezk1"
                                                                                                     className="MpKiNN comp-kei6uezk1 wixui-text-input lPl_oN">
                                                                                                    <div
                                                                                                        className="pUnTVX">
                                                                                                        <input
                                                                                                            name="email"
                                                                                                            id="input_comp-kei6uezk1"
                                                                                                            className="KvoMHf has-custom-focus wixui-text-input__input"
                                                                                                            type="email"
                                                                                                            placeholder="Email *"
                                                                                                            value={formData.email}
                                                                                                            onChange={handleChange}
                                                                                                            required />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div id="comp-kei6uezo1"
                                                                                                     className="MpKiNN comp-kei6uezo1 wixui-text-input">
                                                                                                    <div
                                                                                                        className="pUnTVX">
                                                                                                        <input
                                                                                                            name="companyName"
                                                                                                            id="input_comp-kei6uezo1"
                                                                                                            className="KvoMHf has-custom-focus wixui-text-input__input"
                                                                                                            type="text"
                                                                                                            placeholder="Company Name"
                                                                                                            value={formData.companyName}
                                                                                                            onChange={handleChange}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div id="comp-kei6uezu"
                                                                                                     className="MpKiNN comp-kei6uezu wixui-text-input">
                                                                                                    <div
                                                                                                        className="pUnTVX">
                                                                                                        <input

                                                                                                            id="input_comp-kei6uezu"
                                                                                                            className="KvoMHf has-custom-focus wixui-text-input__input"
                                                                                                            type="text"
                                                                                                            name="cityState"
                                                                                                            placeholder="City, State"
                                                                                                            value={formData.cityState}
                                                                                                            onChange={handleChange}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div id="comp-kei6uezy"
                                                                                                     className="MpKiNN comp-kei6uezy wixui-text-input lPl_oN">
                                                                                                    <div
                                                                                                        className="pUnTVX">
                                                                                                        <input

                                                                                                            id="input_comp-kei6uezy"
                                                                                                            className="KvoMHf has-custom-focus wixui-text-input__input"
                                                                                                            type="text"
                                                                                                            name="subject"
                                                                                                            placeholder="Subject *"
                                                                                                            value={formData.subject}
                                                                                                            onChange={handleChange}
                                                                                                            required/>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div id="comp-kei6uf02"
                                                                                                     className="snt4Te comp-kei6uf02 wixui-text-box NyPO4H">
                                                                                                    <label
                                                                                                        htmlFor="textarea_comp-kei6uf02"
                                                                                                        className="PSkPrR wixui-text-box__label"></label><textarea
                                                                                                    id="textarea_comp-kei6uf02"
                                                                                                    className="rEindN has-custom-focus wixui-text-box__input"
                                                                                                    name="message"
                                                                                                    placeholder="Message *"
                                                                                                    value={formData.message}
                                                                                                    onChange={handleChange}
                                                                                                    required
                                                                                                    ></textarea>
                                                                                                </div>
                                                                                                <a  href={generateMailtoLink()} onClick={(e) => e.preventDefault()}
                                                                                                    className="comp-kei6uf0b FubTgk"
                                                                                                    id="comp-kei6uf0b"
                                                                                                    aria-disabled="false">
                                                                                                    <div

                                                                                                        className="uDW_Qe wixui-button PlZyDq"><spaSn
                                                                                                        className="l7_2fn wixui-button__label">Send</spaSn>
                                                                                                    </div>
                                                                                                </a>
                                                                                                <div id="comp-kei6uf0k"
                                                                                                     className="HcOXKn SxM0TO QxJLC3 lq2cno comp-kei6uf0k wixui-rich-text"
                                                                                                     data-testid="richTextElement">
                                                                                                    <p dir="ltr"
                                                                                                       style={{fontSize: '14px', textAlign: 'center'}}
                                                                                                       className="wixui-rich-text__text">
                                                                                                                    <span
                                                                                                                        style={{color: '#68B04D'}}
                                                                                                                        className="wixui-rich-text__text"><span
                                                                                                                        style={{fontFamily: 'helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif'}}
                                                                                                                        className="wixui-rich-text__text"><span
                                                                                                                        style={{fontSize: '14px'}}
                                                                                                                        className="wixui-rich-text__text">Thank
                                                                                                                                you
                                                                                                                                for
                                                                                                                                submitting
                                                                                                                                your
                                                                                                                                inquiry.
                                                                                                                                We
                                                                                                                                will
                                                                                                                                try
                                                                                                                                our
                                                                                                                                best
                                                                                                                                to
                                                                                                                                respond
                                                                                                                                within
                                                                                                                                72
                                                                                                                                hours
                                                                                                                                if
                                                                                                                                you
                                                                                                                                are
                                                                                                                                a
                                                                                                                                good
                                                                                                                                fit.</span>
                                                                                                                    </span>
                                                                                                                    </span>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );

}

export default ContactMain;