import React from "react";
import OurPrograss from "../static/img/Verde Global (2).png";
import ImageMap from "../static/img/New Map.png";
import WhiteLoge from "../static/img/White Logo.png";
import Fashion from "../static/img/1. Fashion.png";
import Beauty from "../static/img/2. Beauty.png";
import Agriculture from "../static/img/3. Agriculture.png";
import GreenFintech from "../static/img/4. Green FinTech.png";
import Sustainable from "../static/img/5. Sustainable Packaging.png";
import OurProsessMainInest from "../static/img/invest.png";

function OurProsessMain() {
    const styles = {
        mainContainer: {
            padding: "30% 0px 0px 0px",
            maxWidth: "100%",
            margin: "0 auto",
            boxSizing: "border-box", // Prevents content overflow
            overflow: "hidden",
        },
        section: {
            padding: "1.5em 10px",
            maxWidth: "100%", // Ensure the section doesn't extend beyond the viewport
            boxSizing: "border-box",
        },
        titleContainer: {
            position: "relative",
            marginBottom: "1em",
            padding: "0 10px", // Add padding for mobile responsiveness
        },
        title: {
            fontSize: "28px",
            lineHeight: "1.4",
            fontWeight: "700",
            fontFamily: "Libre Baskerville, serif",
            color: "#313f4f",
            display: "inline-block",
            marginBottom: "0.5em",
        },
        underline: {
            position: "absolute",
            // bottom: "-3px", // Adjust space between text and underline
            left: "10px",
            width: "75px", // Adjust the width of the underline
            height: "3px",
            backgroundColor: "#c2b280", // Match the underline color
        },
        subtitle: {
            fontSize: "18px",
            lineHeight: "1.8",
            color: "#313f4f",
            marginTop: "1em",
            fontFamily: "'Libre Baskerville', serif", // Font family
            padding: "0 10px", // Add padding for text alignment
        },
        image: {
            width: "100%",
            height: "auto",
            marginTop: "2em",

        },
        iconContainer: {
            paddingTop:"10px",
            display: "flex",
            flexWrap: "wrap", // Allow items to wrap onto the next line if necessary
            justifyContent: "center", // Center items horizontally
            alignItems: "center", // Center items vertically
            gap: "10px", // Add space between items
            textAlign: "center", // Center the text under each icon
            padding: "0 10px", // Add padding for responsiveness
            boxSizing: "border-box", // Ensure padding and borders are included in dimensions
        },
        iconBox: {
            width:"30%",
            textAlign: "center",
            marginBottom: "1em",
        },
        iconText: {
            fontSize: "16px",
            color: "#313f4f",
            marginTop: "0.5em",
            fontFamily: "'Libre Baskerville', serif",
            fontWeight: "700",
            letterSpacing: "0.01em",
        },
        footer: {
            backgroundColor: "#c2b280",
            color: "#fff",
            textAlign: "center",
            padding: "4em 0",
        },
        footerTitle: {
            color: "#fff ",
            fontSize: "24px",
            fontWeight: "700",
            marginBottom: "2em",

        },
        footerButton: {
            backgroundColor: "#fff",
            color: "#313f4f",
            fontSize: "16px",
            padding: "12px 24px",
            borderRadius: "5px",
            textDecoration: "none",
            fontFamily: "'Libre Baskerville', serif", // Font family
            fontWeight: "bold",
        },
    };

    return (
        <main id="PAGES_CONTAINER" className="PAGES_CONTAINER" style={styles.mainContainer}>
            {/* Section 1: Who We Invest In */}
            <section style={{...styles.section, textAlign: "left"}}>
                <div style={styles.titleContainer}>
                    <h5 style={styles.title}>Who We Invest In</h5>
                    <div style={styles.underline}></div>
                </div>
                <p style={styles.subtitle}>
                    When making an investment decision, Verde Global seeks visionary founders and
                    exceptional leadership teams. We specifically focus on evaluating the leader/s
                    of each company across the following criteria by leveraging formal tools &
                    assessments:
                </p>
                <img src={OurPrograss} style={styles.image} alt="Our Progress"/>
            </section>

            {/* Section 2: What We Invest In */}
            <section style={styles.section}>
                <h5 style={{...styles.title, textAlign: "center"}}>What We Invest In</h5>
                <div style={{...styles.underline , width: "85px"}}></div>

                <p style={{...styles.subtitle,}}>
                    We focus on post-concept companies in the sustainability space, raising Seed or Pre-Series
                    A funding, with a deep understanding of their market.
                </p>
                <div style={styles.iconContainer}>
                    <div style={styles.iconBox}>
                        <img src={Fashion} alt="Fashion" style={styles.image}/>
                        <div style={styles.iconText}>Fashion</div>
                    </div>
                    <div style={styles.iconBox}>
                        <img src={Beauty} alt="Beauty" style={styles.image}/>
                        <div style={styles.iconText}>Beauty</div>
                    </div>
                    <div style={styles.iconBox}>
                        <img src={Agriculture} alt="Agriculture" style={styles.image}/>
                        <div style={styles.iconText}>Agriculture</div>
                    </div>
                    <div style={styles.iconBox}>
                        <img src={GreenFintech} alt="Green FinTech" style={styles.image}/>
                        <div style={styles.iconText}>Green FinTech</div>
                    </div>
                    <div style={styles.iconBox}>
                        <img src={Sustainable} alt="Sustainable Packaging" style={styles.image}/>
                        <div style={styles.iconText}>Sustainable Packaging</div>
                    </div>
                </div>
            </section>

            {/* Section 3: Where We Invest */}
            <section style={styles.section}>
                <h5 style={{...styles.title, textAlign: "center"}}>Where We Invest</h5>
                <div style={{...styles.underline, width: "105px"}}></div>
                <p style={{...styles.subtitle,}}>
                    We focus on founders outside core startup ecosystems, where capital is less accessible.
                </p>
                <img src={ImageMap} style={styles.image} alt="Investment Locations"/>
            </section>

            {/* Section 4: How We Invest */}
            <section style={styles.section}>
                <h5 style={{...styles.title, textAlign: "center"}}>How We Invest</h5>
                <div style={{...styles.underline, width: "65px"}}></div>
                <p style={{...styles.subtitle}}>
                    Our diligence focuses on leadership, as founders are key to a company’s success. We seek
                    companies building toward sustainability.
                </p>
                <img src={OurProsessMainInest} style={styles.image} alt="Investment Process"/>
            </section>

            {/* Footer */}
            <section style={styles.footer}>
                <img src={WhiteLoge} style={{width: "50%", marginBottom: "1.5em"}} alt="Logo"/>
                <h5 style={styles.footerTitle}>Want to learn more? Get in touch.</h5>
                <a href="/contact" style={styles.footerButton}>
                    Contact Us
                </a>
            </section>
        </main>
    );
}

export default OurProsessMain;
