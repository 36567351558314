import {ParallaxBanner, ParallaxProvider} from "react-scroll-parallax";
import MainPageImage from '../static/img/MainPagePhoto.png'
import CollaborativeStrategy from '../static/img/1.png'
import TargetCompanies from '../static/img/2.png'
import LeadershipFocus from '../static/img/3.png'

function HomeMain() {


    return (   <ParallaxProvider>

        <main
            id="PAGES_CONTAINER"
            className="PAGES_CONTAINER"
            tabIndex="-1"
            data-main-content="true"
        >
            <div id="SITE_PAGES" className="JsJXaX SITE_PAGES">
                <div id="SITE_PAGES_TRANSITION_GROUP" className="AnQkDU">
                    <div id="c1dmp" className="dBAkHi c1dmp">
                        <div className="PFkO7r wixui-page" data-testid="page-bg"></div>
                        <div className="HT5ybB">
                            <div id="Containerc1dmp" className="Containerc1dmp SPY_vo">
                                <div
                                    data-mesh-id="Containerc1dmpinlineContent"
                                    data-testid="inline-content"
                                    className=""
                                >
                                    <div
                                        data-mesh-id="Containerc1dmpinlineContent-gridContainer"
                                        data-testid="mesh-container-content"
                                    >
                                        <section
                                            id="comp-lh3d5tp5"
                                            tabIndex="-1"
                                            className="Oqnisf comp-lh3d5tp5 wixui-section"
                                            data-block-level-container="ClassicSection"
                                        >

                                            <div
                                                data-mesh-id="comp-lh3d5tp5inlineContent"
                                                data-testid="inline-content"
                                                className="s1-inner-div-1"
                                            >
                                                <div data-aos="fade-up" data-aos-easing="ease-in-sine"
                                                    data-mesh-id="comp-lh3d5tp5inlineContent-gridContainer"
                                                    data-testid="mesh-container-content"
                                                     className="s1-inner-div-2"
                                                >
                                                    <div
                                                        id="comp-isye9g3q"
                                                        className="s1-inner-div-3 HcOXKn c9GqVL QxJLC3 lq2cno comp-isye9g3q wixui-rich-text"
                                                        data-testid="richTextElement"
                                                        data-motion-enter="done"

                                                    >
                                                        <h4

                                                                                          className="font_4 wixui-rich-text__text s1-h4"
                                                                                          style={{ fontSize: '60px',  }}
                                                                                      >
                                                        <span
                                                            style={{ letterSpacing: 'normal' }}
                                                            className="wixui-rich-text__text"
                                                        ><span
                                                            style={{ fontSize: '60px' }}
                                                            className="wixui-rich-text__text"
                                                        ><span
                                                            style={{ fontWeight:'bold' }}
                                                            className="wixui-rich-text__text"
                                                        ><span
                                                          style={{ fontFamily: 'Libre Baskerville, serif' }}
                                                            className="wixui-rich-text__text"
                                                        ><span
                                                            style={{ color: '#313f4f' }}
                                                            className="wixui-rich-text__text"
                                                        >A Sustainability-Focused Fund Investing in Early-Stage Companies</span
                                                        ></span
                                                        ></span
                                                        ></span
                                                        ></span
                                                        >
                                                                                      </h4>

                                                    </div>
                                                    <div
                                                        id="comp-jzxfpoyq"
                                                        className="comp-jzxfpoyq SPY_vo"
                                                        data-motion-enter="done"
                                                    >
                                                        <div
                                                            data-mesh-id="comp-jzxfpoyqinlineContent"
                                                            data-testid="inline-content"
                                                            className="comp-jzxfpoyq1"
                                                        >
                                                            <div
                                                                data-mesh-id="comp-jzxfpoyqinlineContent-gridContainer"
                                                                data-testid="mesh-container-content"
                                                            >
                                                                <div
                                                                    id="comp-isyeclmz"
                                                                    className="comp-isyeclmz aVng1S wixui-horizontal-line"
                                                                ></div>
                                                                <div
                                                                    id="comp-jxg4bfx9"
                                                                    className="comp-jxg4bfx9 aVng1S wixui-horizontal-line"
                                                                ></div>
                                                                <div
                                                                    id="comp-jxg4amip"
                                                                    className="comp-jxg4amip aVng1S wixui-horizontal-line"
                                                                ></div>
                                                                <div
                                                                    data-mesh-id="comp-jzxfpoyqinlineContent-wedge-3"
                                                                ></div>
                                                                <div
                                                                    data-mesh-id="comp-jzxfpoyqinlineContent-wedge-5"
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section
                                            id="comp-lh3d5tp6"
                                            tabIndex="-1"
                                            className="Oqnisf comp-lh3d5tp6 wixui-section"
                                            data-block-level-container="ClassicSection"
                                        >
                                            <div
                                                id="bgLayers_comp-lh3d5tp6"
                                                data-hook="bgLayers"
                                                data-motion-part="BG_LAYER"
                                                className="MW5IWV"
                                            >
                                                <div
                                                    data-testid="colorUnderlay"
                                                    className="LWbAav Kv1aVt"
                                                ></div>
                                                <div
                                                    id="bgMedia_comp-lh3d5tp6"
                                                    data-motion-part="BG_MEDIA"
                                                    className="VgO9Yg"
                                                ></div>
                                            </div>
                                            <div
                                                data-mesh-id="comp-lh3d5tp6inlineContent"
                                                data-testid="inline-content"

                                            >
                                                <div
                                                    data-mesh-id="comp-lh3d5tp6inlineContent-gridContainer"
                                                    data-testid="mesh-container-content"
                                                >
                                                    {/*<ParallaxComponent />*/}

                                                    <ParallaxBanner
                                                        className="aspect"
                                                        layers={[
                                                            {
                                                            image: MainPageImage,
                                                            speed: -10 ,
                                                                style: {
                                                                    backgroundPosition: 'center bottom', // Position the image at the top
                                                                    backgroundSize: 'cover', // Ensure it covers the area
                                                                    // height: '100%', // Ensure it takes the full height
                                                                    transform: 'translateY(400px)' // Move the image layer up
                                                                }}
                                                        ]}


                                                    />

                                                    <section
                                                        id="comp-jxg5n3pe"
                                                        className="comp-jxg5n3pe CohWsy wixui-column-strip"
                                                    >
                                                        <div
                                                            id="bgLayers_comp-jxg5n3pe"
                                                            data-hook="bgLayers"
                                                            data-motion-part="BG_LAYER"
                                                            className="if7Vw2"
                                                        >
                                                            <div
                                                                data-testid="colorUnderlay"
                                                                className="tcElKx i1tH8h"
                                                            ></div>
                                                            <div
                                                                id="bgMedia_comp-jxg5n3pe"
                                                                data-motion-part="BG_MEDIA"
                                                                className="wG8dni"
                                                            ></div>
                                                        </div>
                                                        <div data-testid="columns" className="V5AUxf">
                                                            <div
                                                                id="comp-jxg5n3t6"
                                                                className="comp-jxg5n3t6 YzqVVZ wixui-column-strip__column"
                                                            >
                                                                <div
                                                                    id="bgLayers_comp-jxg5n3t6"
                                                                    data-hook="bgLayers"
                                                                    data-motion-part="BG_LAYER"
                                                                    className="MW5IWV"
                                                                >
                                                                    <div
                                                                        data-testid="colorUnderlay"
                                                                        className="LWbAav Kv1aVt"
                                                                    ></div>
                                                                    <div
                                                                        id="bgMedia_comp-jxg5n3t6"
                                                                        data-motion-part="BG_MEDIA"
                                                                        className="VgO9Yg"
                                                                    ></div>
                                                                </div>
                                                                <div
                                                                    data-mesh-id="comp-jxg5n3t6inlineContent"
                                                                    data-testid="inline-content"
                                                                    className=""
                                                                >
                                                                    <div
                                                                        data-mesh-id="comp-jxg5n3t6inlineContent-gridContainer"
                                                                        data-testid="mesh-container-content" data-aos="fade-up" data-aos-easing="ease-in-sine"
                                                                    >
                                                                        <div
                                                                            id="comp-jxg5n3t9"
                                                                            className="HcOXKn c9GqVL QxJLC3 lq2cno comp-jxg5n3t9 wixui-rich-text"
                                                                            data-testid="richTextElement"
                                                                            data-motion-enter="done"
                                                                        >
                                                                            <h2
                                                                                className="font_2 wixui-rich-text__text"
                                                                                style={{
                                                                                    fontSize: '35px',
                                                                                    lineHeight: '1.4em',
                                                                                    textAlign: 'center'
                                                                                }}
                                                                            >
                                    <span
                                        style={{letterSpacing: 'normal'}}
                                        className="wixui-rich-text__text"
                                    ><span
                                        style={{fontSize: '35px'}}
                                        className="wixui-rich-text__text"
                                    ><span
                                        style={{fontWeight: 'bold'}}
                                        className="wixui-rich-text__text"
                                    ><span
                                        style={{fontFamily: 'Libre Baskerville, serif'}}
                                        className="wixui-rich-text__text"
                                    ><span
                                        style={{
                                            color: '#ffffff'
                                        }}
                                        className="wixui-rich-text__text"
                                    ><span
                                        style={{fontStyle: 'normal'}}
                                        className="wixui-rich-text__text"
                                    >Verde Global leverages the diverse expertise of our investors to make strategic investments in sustainability-driven businesses.</span
                                    ></span
                                    ></span
                                    ></span
                                    ></span
                                    ></span
                                    >
                                                                            </h2>
                                                                        </div>
                                                                        <div
                                                                            id="comp-jxg5n3th"
                                                                            className="comp-jxg5n3th aVng1S wixui-horizontal-line"
                                                                            data-motion-enter="done"
                                                                        ></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </section>
                                        <section
                                            id="comp-lh3d5tp61"
                                            tabIndex="-1"
                                            className="Oqnisf comp-lh3d5tp61 wixui-section"
                                            data-block-level-container="ClassicSection"
                                        >
                                            <div
                                                id="bgLayers_comp-lh3d5tp61"
                                                data-hook="bgLayers"
                                                data-motion-part="BG_LAYER"
                                                className="MW5IWV"
                                            >
                                                <div
                                                    data-testid="colorUnderlay"
                                                    className="LWbAav Kv1aVt"
                                                ></div>
                                                <div
                                                    id="bgMedia_comp-lh3d5tp61"
                                                    data-motion-part="BG_MEDIA"
                                                    className="VgO9Yg"
                                                ></div>
                                            </div>
                                            <div
                                                data-mesh-id="comp-lh3d5tp61inlineContent"
                                                data-testid="inline-content"
                                                className=""
                                            >
                                                <div
                                                    data-mesh-id="comp-lh3d5tp61inlineContent-gridContainer"
                                                    data-testid="mesh-container-content"
                                                >
                                                    <section
                                                        id="comp-isyhodcr"
                                                        className="comp-isyhodcr CohWsy wixui-column-strip"
                                                    >
                                                        <div
                                                            id="bgLayers_comp-isyhodcr"
                                                            data-hook="bgLayers"
                                                            data-motion-part="BG_LAYER"
                                                            className="if7Vw2"
                                                        >
                                                            <div
                                                                data-testid="colorUnderlay"
                                                                className="tcElKx i1tH8h"
                                                            ></div>
                                                            <div
                                                                id="bgMedia_comp-isyhodcr"
                                                                data-motion-part="BG_MEDIA"
                                                                className="wG8dni"
                                                            ></div>
                                                        </div>
                                                        <div data-testid="columns" className="V5AUxf">
                                                            <div
                                                                id="comp-isyhode3"
                                                                className="comp-isyhode3 YzqVVZ wixui-column-strip__column"
                                                            >
                                                                <div
                                                                    id="bgLayers_comp-isyhode3"
                                                                    data-hook="bgLayers"
                                                                    data-motion-part="BG_LAYER"
                                                                    className="MW5IWV"
                                                                >
                                                                    <div
                                                                        data-testid="colorUnderlay"
                                                                        className="LWbAav Kv1aVt"
                                                                    ></div>
                                                                    <div
                                                                        id="bgMedia_comp-isyhode3"
                                                                        data-motion-part="BG_MEDIA"
                                                                        className="VgO9Yg"
                                                                    ></div>
                                                                </div>
                                                                <div
                                                                    data-mesh-id="comp-isyhode3inlineContent"
                                                                    data-testid="inline-content"
                                                                    className=""
                                                                >
                                                                    <div
                                                                        data-mesh-id="comp-isyhode3inlineContent-gridContainer"
                                                                        data-testid="mesh-container-content"
                                                                    >
                                                                        <div
                                                                            id="comp-jzxfr3sy"
                                                                            className="comp-jzxfr3sy SPY_vo"
                                                                            data-motion-enter="done"
                                                                        >
                                                                            <div
                                                                                data-mesh-id="comp-jzxfr3syinlineContent"
                                                                                data-testid="inline-content"
                                                                                className=""
                                                                            >
                                                                                <div
                                                                                    data-mesh-id="comp-jzxfr3syinlineContent-gridContainer"
                                                                                    data-testid="mesh-container-content"
                                                                                >
                                                                                    <div
                                                                                        id="comp-jxm0lp0w"
                                                                                        className="HcOXKn c9GqVL QxJLC3 lq2cno comp-jxm0lp0w wixui-rich-text"
                                                                                        data-testid="richTextElement"
                                                                                    >
                                                                                        <h5 data-aos="fade-up" data-aos-easing="ease-in-sine"
                                                                                            className="font_5 wixui-rich-text__text"
                                                                                            style={{
                                                                                                fontSize: '35px',
                                                                                                lineHeight: 'normal'

                                                                                            }}
                                                                                        >
                                          <span
                                              style={{ fontSize: '35px' }}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{fontFamily: 'Libre Baskerville, serif', fontWeight: '700',
                                                  letterSpacing:' 0.01em'}}



                                              className="wixui-rich-text__text"
                                          >Our Difference</span
                                          ></span
                                          >
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div
                                                                                        id="comp-isyebw5v"
                                                                                        className="HcOXKn c9GqVL QxJLC3 lq2cno comp-isyebw5v wixui-rich-text"
                                                                                        data-testid="richTextElement"
                                                                                        data-motion-enter="done"
                                                                                        data-aos="fade-up" data-aos-easing="ease-in-sine"
                                                                                    >
                                                                                        <p
                                                                                            className="font_7 wixui-rich-text__text"
                                                                                            style={{
                                                                                                fontSize: '20px',
                                                                                                lineHeight: 'normal',

                                                                                            }}
                                                                                        >
                                          <span
                                             style= {{ letterSpacing: 'normal' }}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{fontSize: '20px'}}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{ color: '#313f4f' }}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{
                                                    fontFamily: 'proxima-n-w01-reg,sans-serif'}}

                                              className="wixui-rich-text__text"
                                          >Verde Global leverages the diverse expertise of our investors to make strategic investments in sustainability-driven businesses.</span
                                          ></span
                                          ></span
                                          ></span
                                          >
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            id="comp-jzxfrghb"
                                                                            className="comp-jzxfrghb SPY_vo"
                                                                            data-motion-enter="done"
                                                                            data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="500"
                                                                        >
                                                                            <div
                                                                                data-mesh-id="comp-jzxfrghbinlineContent"
                                                                                data-testid="inline-content"
                                                                                className=""
                                                                            >
                                                                                <div
                                                                                    data-mesh-id="comp-jzxfrghbinlineContent-gridContainer"
                                                                                    data-testid="mesh-container-content"

                                                                                >
                                                                                    <div
                                                                                        id="comp-jxg9reuw"
                                                                                        className="comp-jxg9reuw wixui-vector-image"

                                                                                    >
                                                                                        <div
                                                                                            data-testid="svgRoot-comp-jxg9reuw"
                                                                                            className="AKxYR5 comp-jxg9reuw"
                                                                                        >
                                                                                         <img src={CollaborativeStrategy} alt={""} style={{width:'100%',height:'100%'}}/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        id="comp-jxg9fjeg"
                                                                                        className="HcOXKn c9GqVL QxJLC3 lq2cno comp-jxg9fjeg wixui-rich-text"
                                                                                        data-testid="richTextElement"

                                                                                    >
                                                                                        <h5
                                                                                            className="font_5 wixui-rich-text__text"
                                                                                            style={{
                                                                                                fontSize: '20px',
                                                                                                lineHeight: 'normal',
                                                                                                textAlign: 'center'
                                                                                            }}
                                                                                        >
                                          <span
                                              style={{ letterSpacing: 'normal' }}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{fontSize: '20px'}}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{ letterSpacing: 'normal' }}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{fontFamily: 'Libre Baskerville, serif'}}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{ color: '#313f4f' }}
                                              className="wixui-rich-text__text"
                                          >Collaborative
                                                    Strategy</span
                                          ></span
                                          ></span
                                          ></span
                                          ></span
                                          >
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div
                                                                                        id="comp-jxg9fjff"
                                                                                        className="HcOXKn c9GqVL QxJLC3 lq2cno comp-jxg9fjff wixui-rich-text"
                                                                                        data-testid="richTextElement"
                                                                                    >
                                                                                        <p
                                                                                            className="font_9 wixui-rich-text__text"
                                                                                            style={{
                                                                                                textAlign: 'center',
                                            fontSize: '16px'
                                          }}
                                                                                        >
                                                                                            Collaborative process leveraging the investors' diverse backgrounds in sustainability and active participation in decisions.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            id="comp-jzxfrib0"
                                                                            className="comp-jzxfrib0 SPY_vo"
                                                                            data-motion-enter="done"
                                                                            data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="1000"
                                                                        >
                                                                            <div
                                                                                data-mesh-id="comp-jzxfrib0inlineContent"
                                                                                data-testid="inline-content"
                                                                                className=""
                                                                            >
                                                                                <div
                                                                                    data-mesh-id="comp-jzxfrib0inlineContent-gridContainer"
                                                                                    data-testid="mesh-container-content"
                                                                                >
                                                                                    <div
                                                                                        id="comp-jxg9rruk"
                                                                                        className="comp-jxg9rruk wixui-vector-image"
                                                                                    >
                                                                                        <div
                                                                                            data-testid="svgRoot-comp-jxg9rruk"
                                                                                            className="AKxYR5 comp-jxg9rruk"
                                                                                        >
                                                                                            <img
                                                                                                src={TargetCompanies}
                                                                                                alt={""} style={{
                                                                                                width: '100%',
                                                                                                height: '100%'
                                                                                            }}/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        id="comp-jxg9ett4"
                                                                                        className="HcOXKn c9GqVL QxJLC3 lq2cno comp-jxg9ett4 wixui-rich-text"
                                                                                        data-testid="richTextElement"
                                                                                    >
                                                                                        <h5
                                                                                            className="font_5 wixui-rich-text__text"
                                                                                            style={{

                                                                                                fontSize: '20px',
                                                                                                lineHeight: 'normal',
                                                                                                textAlign: 'center'
                                                                                            }}
                                                                                        >
                                          <span
                                              style={{ letterSpacing: 'normal' }}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{fontSize: '20px'}}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{ letterSpacing: 'normal' }}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{fontFamily: 'Libre Baskerville, serif'}}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{ color: '#313f4f' }}
                                              className="wixui-rich-text__text"
                                          >Target Companies</span
                                          ></span
                                          ></span
                                          ></span
                                          ></span
                                          >
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div
                                                                                        id="comp-jxg9ettv"
                                                                                        className="HcOXKn c9GqVL QxJLC3 lq2cno comp-jxg9ettv wixui-rich-text"
                                                                                        data-testid="richTextElement"
                                                                                    >
                                                                                        <p
                                                                                            className="font_9 wixui-rich-text__text"
                                                                                            style={{
                                                                                                textAlign: 'center',
                                            fontSize: '16px'
                                          }}
                                                                                        >
                                                                                            Targeting sustainable businesses in fashion, beauty, and related industries with a scalable competitive advantage.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            id="comp-jzxfrk2z"
                                                                            className="comp-jzxfrk2z SPY_vo"
                                                                            data-motion-enter="done"
                                                                            data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="1500"
                                                                        >
                                                                            <div
                                                                                data-mesh-id="comp-jzxfrk2zinlineContent"
                                                                                data-testid="inline-content"
                                                                                className=""
                                                                            >
                                                                                <div
                                                                                    data-mesh-id="comp-jzxfrk2zinlineContent-gridContainer"
                                                                                    data-testid="mesh-container-content"
                                                                                >
                                                                                    <div
                                                                                        id="comp-jxg9rme3"
                                                                                        className="comp-jxg9rme3 wixui-vector-image"
                                                                                    >
                                                                                        <div
                                                                                            data-testid="svgRoot-comp-jxg9rme3"
                                                                                            className="AKxYR5 comp-jxg9rme3"
                                                                                        >
                                                                                            <img
                                                                                                src={LeadershipFocus}
                                                                                                alt={""} style={{
                                                                                                width: '100%',
                                                                                                height: '100%'
                                                                                            }}/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        id="comp-jxg9fjh8"
                                                                                        className="HcOXKn SxM0TO QxJLC3 lq2cno comp-jxg9fjh8 wixui-rich-text"
                                                                                        data-testid="richTextElement"
                                                                                    >
                                                                                        <h5
                                                                                            className="font_5 wixui-rich-text__text"
                                                                                           style={{
                                                                                            textAlign: 'center',
                                                                                            fontSize: '16px'
                                                                                        }}
                                                                                        >
                                          <span
                                              style={{fontSize: '20px'}}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{letterSpacing: '0em'}}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{fontFamily: 'Libre Baskerville, serif'}}


                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{ letterSpacing: 'normal' }}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{ color: '#313f4f' }}
                                              className="wixui-rich-text__text"
                                          >Leadership Focus</span
                                          ></span
                                          ></span
                                          ></span
                                          ></span
                                          >
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div
                                                                                        id="comp-jxg9fjg9"
                                                                                        className="HcOXKn c9GqVL QxJLC3 lq2cno comp-jxg9fjg9 wixui-rich-text"
                                                                                        data-testid="richTextElement"
                                                                                    >
                                                                                        <p
                                                                                            className="font_9 wixui-rich-text__text"
                                                                                            style={{
                                                                                                textAlign: 'center',
                                            fontSize: '16px'
                                          }}
                                                                                        >
                                                                                            Evaluating leadership teams with a focus on sustainability, identifying gaps, and assessing company culture pre- and post-investment.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div
                                                                            data-mesh-id="comp-isyhode3inlineContent-wedge-3"
                                                                        ></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </section>
                                        <section
                                            id="comp-lh3d5tp62"
                                            tabIndex="-1"
                                            className="Oqnisf comp-lh3d5tp62 wixui-section"
                                            data-block-level-container="ClassicSection"
                                        >
                                            <div
                                                id="bgLayers_comp-lh3d5tp62"
                                                data-hook="bgLayers"
                                                data-motion-part="BG_LAYER"
                                                className="MW5IWV"
                                            >
                                                <div
                                                    data-testid="colorUnderlay"
                                                    className="LWbAav Kv1aVt"
                                                ></div>
                                                <div
                                                    id="bgMedia_comp-lh3d5tp62"
                                                    data-motion-part="BG_MEDIA"
                                                    className="VgO9Yg"
                                                ></div>
                                            </div>
                                            <div
                                                data-mesh-id="comp-lh3d5tp62inlineContent"
                                                data-testid="inline-content"
                                                className=""
                                            >
                                                <div
                                                    data-mesh-id="comp-lh3d5tp62inlineContent-gridContainer"
                                                    data-testid="mesh-container-content"
                                                >

                                                </div>
                                            </div>
                                        </section>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </ParallaxProvider>
    );
}

export default HomeMain;
