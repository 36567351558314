import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import Home from './pages/Home';
import MobileHome from './pages/MobileHome';
import OurProcess from './pages/OurProcess';
import MobileOurProcess from './pages/MobileOurProcess';
import About from './pages/About';
import MobileAbout from './pages/MobileAbout';
import Contact from './pages/Contact';
import MobileContact from './pages/MobileContact';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useMediaQuery } from 'react-responsive';

function App() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    useEffect(() => {
        AOS.init({
            duration: 1200, // Animation duration
            once: true, // Whether animation should happen only once - while scrolling down
        });
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={isMobile ? <MobileHome /> : <Home />} />
                <Route path="/about" element={isMobile ? <MobileAbout /> : <About />} />
                <Route path="/contact" element={isMobile ? <MobileContact /> : <Contact />} />
                <Route path="/ourProcess" element={isMobile ? <MobileOurProcess /> : <OurProcess />} />
            </Routes>
        </Router>
    );
}

export default App;
