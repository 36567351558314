import Header from "../sections/Header";
import Footer from "../sections/Footer";
import OurProsessMain from "../sections/OurProsessMain";
import '../static/css/our_process.css'
function OurProcess() {
    return (
        <div id="site-root" className="site-root">
            <div id="masterPage" className="mesh-layout remove-wrappers masterPage css-editing-scope">
                <Header/>
                <OurProsessMain/>
                <Footer/>
            </div>
        </div>
    );
}

export default OurProcess;
