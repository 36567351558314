import Header from "../sections/Header";
import Footer from "../sections/Footer";
import ContactMain from "../sections/ContactMain";
import '../static/css/contact.css'
function Contact() {
    return (
        <div id="site-root" className="site-root">
            <div id="masterPage" className="mesh-layout remove-wrappers masterPage css-editing-scope">
                <Header/>
                <ContactMain/>
                <Footer/>
            </div>
        </div>
    );
}

export default Contact;
