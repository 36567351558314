import React from 'react';
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import MainPageImage from '../static/img/MainPagePhoto.png';
import CollaborativeStrategy from '../static/img/1.png';
import TargetCompanies from '../static/img/2.png';
import LeadershipFocus from '../static/img/3.png';

function HomeMain() {
    const styles = {
        firstSection: {
            maxWidth: '100%',
            margin: '0 auto',
            padding: '30% 30px 10% 30px', // 30% top padding
            textAlign: 'center',
        },
        mainTitle: {
            fontSize: '2em',
            lineHeight: '1.8em', // Increased
            fontWeight: 'bold',
            fontFamily: 'Libre Baskerville, serif',
            color: "#313f4f",
            display: 'inline-block',
            textDecoration: 'underline',
            textDecorationColor: '#d0c5a1', // Matching underline color
            textUnderlineOffset: '8px', // Increased spacing between text and underline
            textDecorationThickness: '3px', // Adjust underline thickness
        },
        parallaxImage: {
            backgroundPosition: 'center bottom',
            backgroundSize: 'cover',
            width: '100%',
            transform: 'translateY(-200px)', // Adjusted for better placement
        },
        descriptionSection: {
            backgroundColor: '#228B22', // Green background
            color: '#ffffff',
            padding: '2em 10%',
            textAlign: 'center',
            position: 'relative', // Required for the line positioning
        },
        mainTitle2: {
            fontSize: '2em',
            lineHeight: '1.4em', // Adjust spacing between lines
            fontWeight: 'bold',
            fontFamily: 'Libre Baskerville, serif',
            color: "#ffffff",
            marginBottom: '1em', // Add space below the text
        },
        smallLine: {
            width: '50px', // Adjust width for the line
            height: '3px', // Adjust height for the line
            backgroundColor: '#c2b280', // Use the same color as the text
            margin: '0 auto', // Center the line
            borderRadius: '3px', // Make the ends rounded
        },
        container: {
            maxWidth: '100%',
            margin: '0 auto',
            padding: '2em 0',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', // Responsive grid
            gap: '1.5em',
            textAlign: 'center',
        },
        subSection: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '1em',
        },
        icon: {
            width: '60px',
            height: 'auto',
            marginBottom: '1em',
        },
        subSectionTitle: {
            fontSize: '20px',
            fontWeight: '700',
            fontFamily: 'Libre Baskerville, serif',
            marginBottom: '0.5em',
            color: "#313f4f",

        },
        subSectionText: {
            fontSize: '16px',
            fontFamily: 'proxima-n-w01-reg, sans-serif',
            marginBottom: '1em',
            color: "#313f4f",
            padding: '0 15%', // Add padding on mobile for readability

        },
        subSectionContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: '1em 0',
        },
        subSectionTitle2: {
            fontSize: '20px',
            lineHeight: 'normal',
            fontWeight: '700',
            fontFamily: 'Libre Baskerville, serif',
            marginBottom: '0.5em',

        },
        subSectionText2: {
            fontSize: '16px',
            fontFamily: 'proxima-n-w01-reg, sans-serif',
            marginBottom: '1em',
            padding: '0 15%', // Add padding on mobile for readability
            color: "#313f4f",
        },
    };

    return (
        <ParallaxProvider>
            <main id="PAGES_CONTAINER" className="PAGES_CONTAINER" tabIndex="-1" aria-label="Main Content">
                {/* Title Section */}
                <section style={styles.firstSection}>
                    <h4 style={styles.mainTitle}>
                        A Sustainability-Focused Fund Investing in Early-Stage Companies
                    </h4>
                </section>

                {/* Parallax Banner */}
                <ParallaxBanner
                    layers={[{ image: MainPageImage, speed: 10, style: styles.parallaxImage }]}
                    style={{ aspectRatio: '16/9', width: '100%' }}
                />

                {/* Description Section */}
                <section style={styles.descriptionSection}>
                    <h2 style={styles.mainTitle2}>
                        Verde Global leverages the diverse expertise of our investors to make strategic investments in sustainability-driven businesses.
                    </h2>
                    {/* Small decorative line */}
                    <div style={styles.smallLine}></div>
                </section>

                {/* Features Section */}
                <section style={styles.container}>

                        <div>
                            <h5 style={styles.subSectionTitle2}>Our Difference</h5>
                            <p style={styles.subSectionText2}>
                                Verde Global leverages the diverse expertise of our investors to make strategic
                                investments in sustainability-driven businesses.
                            </p>
                        </div>
                        <div style={styles.subSection}>
                            <img src={CollaborativeStrategy} alt="Collaborative Strategy Icon" style={styles.icon}/>
                            <h5 style={styles.subSectionTitle}>Collaborative Strategy</h5>
                            <p style={styles.subSectionText}>
                                Collaborative process leveraging the investors' diverse backgrounds in sustainability
                                and active participation in decisions.
                            </p>
                        </div>
                        <div style={styles.subSection}>
                            <img src={TargetCompanies} alt="Target Companies Icon" style={styles.icon}/>
                            <h5 style={styles.subSectionTitle}>Target Companies</h5>
                            <p style={styles.subSectionText}>
                                Targeting sustainable businesses in fashion, beauty, and related industries with a
                                scalable competitive advantage.
                            </p>
                        </div>
                        <div style={styles.subSection}>
                            <img src={LeadershipFocus} alt="Leadership Focus Icon" style={styles.icon}/>
                            <h5 style={styles.subSectionTitle}>Leadership Focus</h5>
                            <p style={styles.subSectionText}>
                                Evaluating leadership teams with a focus on sustainability, identifying gaps, and
                                assessing company culture pre- and post-investment.
                            </p>
                        </div>
                </section>
            </main>
        </ParallaxProvider>
);
}

export default HomeMain;
