import  OurPrograss from '../static/img/Verde Global (2).png'
import ImageMap from '../static/img/New Map.png'
import WhiteLoge from '../static/img/White Logo.png'
import Fashion from '../static/img/1. Fashion.png'
import Beauty from '../static/img/2. Beauty.png'
import Agriculture from '../static/img/3. Agriculture.png'
import GreenFintech from '../static/img/4. Green FinTech.png'
import Sustainable from '../static/img/5. Sustainable Packaging.png'
import OurProsessMainInest from '../static/img/invest.png'
function OurProsessMain() {


    return (
        <main
            id="PAGES_CONTAINER"
            className="PAGES_CONTAINER"
            tabIndex="-1"
            data-main-content="true"
        >
            <div id="SITE_PAGES" className="JsJXaX SITE_PAGES">
                <div id="SITE_PAGES_TRANSITION_GROUP" className="AnQkDU">
                    <div id="zm2x4" className="dBAkHi zm2x4">
                        <div className="PFkO7r wixui-page" data-testid="page-bg"></div>
                        <div className="HT5ybB">
                            <div id="Containerzm2x4" className="Containerzm2x4 SPY_vo">
                                <div
                                    data-mesh-id="Containerzm2x4inlineContent"
                                    data-testid="inline-content"
                                    className=""
                                >
                                    <div
                                        data-mesh-id="Containerzm2x4inlineContent-gridContainer"
                                        data-testid="mesh-container-content"
                                    >
                                        <section
                                            id="comp-lh3d5tq2"
                                            tabIndex="-1"
                                            className="Oqnisf comp-lh3d5tq2 wixui-section"
                                            data-block-level-container="classNameicSection"
                                        >
                                            <div
                                                id="bgLayers_comp-lh3d5tq2"
                                                data-hook="bgLayers"
                                                data-motion-part="BG_LAYER"
                                                className="MW5IWV"
                                            >
                                                <div
                                                    data-testid="colorUnderlay"
                                                    className="LWbAav Kv1aVt"
                                                ></div>
                                                <div
                                                    id="bgMedia_comp-lh3d5tq2"
                                                    data-motion-part="BG_MEDIA"
                                                    className="VgO9Yg"
                                                ></div>
                                            </div>
                                            <div
                                                data-mesh-id="comp-lh3d5tq2inlineContent"
                                                data-testid="inline-content"
                                                className=""
                                            >
                                                <div
                                                    data-mesh-id="comp-lh3d5tq2inlineContent-gridContainer"
                                                    data-testid="mesh-container-content"
                                                >
                                                    <section
                                                        id="comp-jxly1qlb"
                                                        className="comp-jxly1qlb CohWsy wixui-column-strip"
                                                    >
                                                        <div
                                                            id="bgLayers_comp-jxly1qlb"
                                                            data-hook="bgLayers"
                                                            data-motion-part="BG_LAYER"
                                                            className="if7Vw2"
                                                        >
                                                            <div
                                                                data-testid="colorUnderlay"
                                                                className="tcElKx i1tH8h"
                                                            ></div>
                                                            <div
                                                                id="bgMedia_comp-jxly1qlb"
                                                                data-motion-part="BG_MEDIA"
                                                                className="wG8dni"
                                                            ></div>
                                                        </div>
                                                        <div data-testid="columns" className="V5AUxf">
                                                            <div
                                                                id="comp-jxly1qmi"
                                                                className="comp-jxly1qmi YzqVVZ wixui-column-strip__column"
                                                            >
                                                                <div
                                                                    id="bgLayers_comp-jxly1qmi"
                                                                    data-hook="bgLayers"
                                                                    data-motion-part="BG_LAYER"
                                                                    className="MW5IWV"
                                                                >
                                                                    <div
                                                                        data-testid="colorUnderlay"
                                                                        className="LWbAav Kv1aVt"
                                                                    ></div>
                                                                    <div
                                                                        id="bgMedia_comp-jxly1qmi"
                                                                        data-motion-part="BG_MEDIA"
                                                                        className="VgO9Yg"
                                                                    ></div>
                                                                </div>
                                                                <div
                                                                    data-mesh-id="comp-jxly1qmiinlineContent"
                                                                    data-testid="inline-content"
                                                                    className=""
                                                                >
                                                                    <div
                                                                        data-mesh-id="comp-jxly1qmiinlineContent-gridContainer"
                                                                        data-testid="mesh-container-content"
                                                                    >
                                                                        <div
                                                                            id="comp-jzxg2t9l"
                                                                            className="comp-jzxg2t9l SPY_vo"
                                                                        >
                                                                            <div
                                                                                data-mesh-id="comp-jzxg2t9linlineContent"
                                                                                data-testid="inline-content"
                                                                                className=""
                                                                            >
                                                                                <div data-aos="fade-up" data-aos-easing="ease-in-sine"
                                                                                    data-mesh-id="comp-jzxg2t9linlineContent-gridContainer"
                                                                                    data-testid="mesh-container-content"
                                                                                >
                                                                                    <div
                                                                                        id="comp-jxm13x6d"
                                                                                        className="HcOXKn SxM0TO QxJLC3 lq2cno comp-jxm13x6d wixui-rich-text"
                                                                                        data-testid="richTextElement"
                                                                                    >
                                                                                        <h5
                                                                                            className="font_5 wixui-rich-text__text"
                                                                                            style= {{fontSize: "35px"}}
                                                                                        >
                                          <span
                                              style ={{color:"#313f4f"}}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{fontSize: "35px"}}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{ fontFamily: "libre baskerville,serif",
                                                  fontWeight: "700",
                                                  letterSpacing: "0.01em"}}
                                              className="wixui-rich-text__text"
                                          >Who we invest in</span
                                          ></span
                                          ></span
                                          >
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div
                                                                                        id="comp-jxm1fbv3"
                                                                                        className="comp-jxm1fbv3 aVng1S wixui-horizontal-line"
                                                                                    ></div>
                                                                                    <div
                                                                                        id="comp-jxly1qmx"
                                                                                        className="HcOXKn SxM0TO QxJLC3 lq2cno comp-jxly1qmx wixui-rich-text"
                                                                                        data-testid="richTextElement"
                                                                                    >
                                                                                        <h5
                                                                                            className="font_5 wixui-rich-text__text"
                                                                                            style={{  fontSize: "18px", lineHeight: "1.4e"}}
                                                                                        >
                                          <span
                                              style={{  fontSize: "18px"}}
                                              className="wixui-rich-text__text"
                                          >When making an investment decision, Verde Global seeks visionary founders and exceptional leadership teams.&nbsp;We
                                            specifically focus on evaluating the
                                            leader/s of each company across the
                                            following criteria by leveraging
                                            formal tools &amp;
                                              assessments:</span
                                          >
                                                                                        </h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            id="comp-jxly1qn0"
                                                                            className="comp-jxly1qn0 wixui-vector-image"
                                                                        >
                                                                            <div
                                                                                data-testid="svgRoot-comp-jxly1qn0"
                                                                                className="AKxYR5 comp-jxly1qn0" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="500"
                                                                            >

                                                                             <img src={OurPrograss} style={{height:' 130%'}} alt={""}/>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section
                                                        id="comp-jxlyt9t5"
                                                        className="comp-jxlyt9t5 CohWsy wixui-column-strip"
                                                    >
                                                        <div
                                                            id="bgLayers_comp-jxlyt9t5"
                                                            data-hook="bgLayers"
                                                            data-motion-part="BG_LAYER"
                                                            className="if7Vw2"
                                                        >
                                                            <div
                                                                data-testid="colorUnderlay"
                                                                className="tcElKx i1tH8h"
                                                            ></div>
                                                            <div
                                                                id="bgMedia_comp-jxlyt9t5"
                                                                data-motion-part="BG_MEDIA"
                                                                className="wG8dni"
                                                            ></div>
                                                        </div>
                                                        <div data-testid="columns" className="V5AUxf">
                                                            <div
                                                                id="comp-jxlyt9u9"
                                                                className="comp-jxlyt9u9 YzqVVZ wixui-column-strip__column"
                                                            >
                                                                <div
                                                                    id="bgLayers_comp-jxlyt9u9"
                                                                    data-hook="bgLayers"
                                                                    data-motion-part="BG_LAYER"
                                                                    className="MW5IWV"
                                                                >
                                                                    <div
                                                                        data-testid="colorUnderlay"
                                                                        className="LWbAav Kv1aVt"
                                                                    ></div>
                                                                    <div
                                                                        id="bgMedia_comp-jxlyt9u9"
                                                                        data-motion-part="BG_MEDIA"
                                                                        className="VgO9Yg"
                                                                    ></div>
                                                                </div>
                                                                <div
                                                                    data-mesh-id="comp-jxlyt9u9inlineContent"
                                                                    data-testid="inline-content"
                                                                    className=""
                                                                >
                                                                    <div
                                                                        data-mesh-id="comp-jxlyt9u9inlineContent-gridContainer"
                                                                        data-testid="mesh-container-content"
                                                                    >
                                                                        <div
                                                                            id="comp-jzxg449j"
                                                                            className="comp-jzxg449j SPY_vo"
                                                                        >
                                                                            <div
                                                                                data-mesh-id="comp-jzxg449jinlineContent"
                                                                                data-testid="inline-content"
                                                                                className=""
                                                                            >
                                                                                <div data-aos="fade-up" data-aos-easing="ease-in-sine"
                                                                                    data-mesh-id="comp-jzxg449jinlineContent-gridContainer"
                                                                                    data-testid="mesh-container-content"
                                                                                >
                                                                                    <div
                                                                                        id="comp-jxm12y8b"
                                                                                        className="HcOXKn SxM0TO QxJLC3 lq2cno comp-jxm12y8b wixui-rich-text"
                                                                                        data-testid="richTextElement"
                                                                                    >
                                                                                        <h5
                                                                                            className="font_5 wixui-rich-text__text"
                                                                                            style={{fontSize: "35px"}}
                                                                                        >
                                          <span
                                              style={{color: "#313f4f"}}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{fontSize: "35px"}}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{
                                                  fontFamily: "libre baskerville,serif",
                                                  fontWeight: "700",
                                                  letterSpacing: "0.01em"
                                              }}
                                              className="wixui-rich-text__text"
                                          >What we invest in</span
                                          ></span
                                          ></span
                                          >
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div
                                                                                        id="comp-jxm1j9w1"
                                                                                        className="comp-jxm1j9w1 aVng1S wixui-horizontal-line"
                                                                                    ></div>
                                                                                    <div
                                                                                        id="comp-jxlytjgw"
                                                                                        className="HcOXKn c9GqVL QxJLC3 lq2cno comp-jxlytjgw wixui-rich-text"
                                                                                        data-testid="richTextElement"
                                                                                    >
                                                                                        <h5
                                                                                            className="font_5 wixui-rich-text__text"
                                                                                            style={{
                                                                                                fontSize: "18px",
                                                                                                lineHeight: "1.4e"
                                                                                            }}
                                                                                        >
                                          <span
                                              style={{letterSpacing: "normal"}}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{fontSize: "18px"}}
                                              className="wixui-rich-text__text"
                                          >We focus on post-concept companies in the sustainability space, raising Seed or Pre-Series A funding, with a deep understanding of their market.&nbsp;</span
                                          ></span
                                          ><br
                                                                                            className="wixui-rich-text__text"
                                                                                        />
                                                                                            <span
                                                                                                style={{letterSpacing: "normal"}}
                                                                                                className="wixui-rich-text__text"
                                                                                            ><span
                                                                                                style={{fontSize: "18px"}}
                                                                                                className="wixui-rich-text__text"
                                                                                            ><span
                                                                                                className="wixGuard wixui-rich-text__text"
                                                                                            >​</span
                                                                                            ></span
                                                                                            ></span
                                                                                            ><br
                                                                                            className="wixui-rich-text__text"
                                                                                        />
                                                                                            <span
                                                                                                style={{letterSpacing: "normal"}}
                                                                                                className="wixui-rich-text__text"
                                                                                            ><span
                                                                                                style={{fontSize: "18px"}}
                                                                                                className="wixui-rich-text__text"
                                                                                            >While focused on sustainability, we see continued innovation in sectors such as fashion, beauty, renewable energy, etc. &nbsp;</span
                                                                                            ></span
                                                                                            >
                                                                                        </h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="icon-container"  >
                                                                            <div className="icon-box" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="500">
                                                                                <img src ={Fashion} alt={""}/>
                                                                                <div className="icon-text">Fashion</div>
                                                                            </div>
                                                                            <div className="icon-box" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="1000">
                                                                                <img src={Beauty} alt={""}/>
                                                                                <div className="icon-text">Beauty</div>
                                                                            </div>
                                                                            <div className="icon-box" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="1500">
                                                                                <img src={Agriculture} alt={""}/>
                                                                                <div className="icon-text">Agriculture &
                                                                                    Food Tech
                                                                                </div>
                                                                            </div>
                                                                            <div className="icon-box"data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="2000">
                                                                                <img src={GreenFintech} alt={""}/>
                                                                                <div className="icon-text">Green
                                                                                    Fintech
                                                                                </div>
                                                                            </div>
                                                                            <div className="icon-box"data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="2500">
                                                                                <img src={Sustainable} alt={""}/>
                                                                                <div className="icon-text">Sustainable
                                                                                    Packaging
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </section>
                                        <section
                                            id="comp-lh3d5tq21"
                                            tabIndex="-1"
                                            className="Oqnisf comp-lh3d5tq21 wixui-section"
                                            data-block-level-container="classNameicSection"
                                        >
                                            <div
                                                id="bgLayers_comp-lh3d5tq21"
                                                data-hook="bgLayers"
                                                data-motion-part="BG_LAYER"
                                                className="MW5IWV"
                                            >
                                                <div
                                                    data-testid="colorUnderlay"
                                                    className="LWbAav Kv1aVt"
                                                ></div>
                                                <div
                                                    id="bgMedia_comp-lh3d5tq21"
                                                    data-motion-part="BG_MEDIA"
                                                    className="VgO9Yg"
                                                ></div>
                                            </div>
                                            <div
                                                data-mesh-id="comp-lh3d5tq21inlineContent"
                                                data-testid="inline-content"
                                                className=""
                                            >
                                                <div
                                                    data-mesh-id="comp-lh3d5tq21inlineContent-gridContainer"
                                                    data-testid="mesh-container-content"
                                                >
                                                    <section
                                                        id="comp-jy04tfje"
                                                        className="comp-jy04tfje CohWsy wixui-column-strip"
                                                    >
                                                        <div
                                                            id="bgLayers_comp-jy04tfje"
                                                            data-hook="bgLayers"
                                                            data-motion-part="BG_LAYER"
                                                            className="if7Vw2"
                                                        >
                                                            <div
                                                                data-testid="colorUnderlay"
                                                                className="tcElKx i1tH8h"
                                                            ></div>
                                                            <div
                                                                id="bgMedia_comp-jy04tfje"
                                                                data-motion-part="BG_MEDIA"
                                                                className="wG8dni"
                                                            ></div>
                                                        </div>
                                                        <div data-testid="columns" className="V5AUxf">
                                                            <div
                                                                id="comp-jy04tfnu"
                                                                className="comp-jy04tfnu YzqVVZ wixui-column-strip__column"
                                                            >
                                                                <div
                                                                    id="bgLayers_comp-jy04tfnu"
                                                                    data-hook="bgLayers"
                                                                    data-motion-part="BG_LAYER"
                                                                    className="MW5IWV"
                                                                >
                                                                    <div
                                                                        data-testid="colorUnderlay"
                                                                        className="LWbAav Kv1aVt"
                                                                    ></div>
                                                                    <div
                                                                        id="bgMedia_comp-jy04tfnu"
                                                                        data-motion-part="BG_MEDIA"
                                                                        className="VgO9Yg"
                                                                    ></div>
                                                                </div>
                                                                <div
                                                                    data-mesh-id="comp-jy04tfnuinlineContent"
                                                                    data-testid="inline-content"
                                                                    className=""
                                                                >
                                                                    <div
                                                                        data-mesh-id="comp-jy04tfnuinlineContent-gridContainer"
                                                                        data-testid="mesh-container-content"
                                                                    >
                                                                        <div
                                                                            id="comp-jzxg77gt"
                                                                            className="comp-jzxg77gt SPY_vo"
                                                                        >
                                                                            <div
                                                                                data-mesh-id="comp-jzxg77gtinlineContent"
                                                                                data-testid="inline-content"
                                                                                className=""
                                                                            >
                                                                                <div
                                                                                    data-mesh-id="comp-jzxg77gtinlineContent-gridContainer"
                                                                                    data-testid="mesh-container-content" data-aos="fade-up" data-aos-easing="ease-in-sine"
                                                                                >
                                                                                    <div
                                                                                        id="comp-jy04xtt0"
                                                                                        className="comp-jy04xtt0 aVng1S wixui-horizontal-line"
                                                                                    ></div>
                                                                                    <div
                                                                                        id="comp-jy04xtrm"
                                                                                        className="HcOXKn SxM0TO QxJLC3 lq2cno comp-jy04xtrm wixui-rich-text"
                                                                                        data-testid="richTextElement"
                                                                                    >
                                                                                        <h5
                                                                                            className="font_5 wixui-rich-text__text"
                                                                                            style={{fontSize: "35px"}}
                                                                                        >
                                          <span
                                              style={{color: "#313f4f"}}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{
                                                  fontFamily: "libre baskerville,serif",
                                                  fontWeight: "700",
                                                  letterSpacing: "0.01em"
                                              }}
                                              className="wixui-rich-text__text"
                                          >Where we invest</span
                                          ></span
                                          >
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div
                                                                                        id="comp-jy04xtsd"
                                                                                        className="HcOXKn c9GqVL QxJLC3 lq2cno comp-jy04xtsd wixui-rich-text"
                                                                                        data-testid="richTextElement"
                                                                                    >
                                                                                        <h5
                                                                                            className="font_5 wixui-rich-text__text"
                                                                                            style={{
                                                                                                lineHeight: "0.6em",
                                                                                                fontSize: "35px"
                                                                                            }}
                                                                                        >
                                          <span
                                              style={{letterSpacing: "normal"}}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{fontSize: "18px"}}
                                              className="wixui-rich-text__text"
                                          >As sustainability-driven entrepreneurs, we focus on founders outside of core startup ecosystems, where capital is less accessible.</span
                                          ></span
                                          >
                                                                                        </h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            id="comp-jy04xk0m"
                                                                            className="comp-jy04xk0m wixui-vector-image"
                                                                        >
                                                                            <div
                                                                                data-testid="svgRoot-comp-jy04xk0m"
                                                                                className="AKxYR5 comp-jy04xk0m" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="500"
                                                                            >

                                                                                <img src={ImageMap} alt={""}
                                                                                     style={{width: '130%'}}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </section>
                                        <section
                                            id="comp-lh3d5tq3"
                                            tabIndex="-1"
                                            className="Oqnisf comp-lh3d5tq3 wixui-section"
                                            data-block-level-container="classNameicSection"
                                        >
                                            <div
                                                id="bgLayers_comp-lh3d5tq3"
                                                data-hook="bgLayers"
                                                data-motion-part="BG_LAYER"
                                                className="MW5IWV"
                                            >
                                                <div
                                                    data-testid="colorUnderlay"
                                                    className="LWbAav Kv1aVt"
                                                ></div>
                                                <div
                                                    id="bgMedia_comp-lh3d5tq3"
                                                    data-motion-part="BG_MEDIA"
                                                    className="VgO9Yg"
                                                ></div>
                                            </div>
                                            <div
                                                data-mesh-id="comp-lh3d5tq3inlineContent"
                                                data-testid="inline-content"
                                                className=""
                                            >
                                                <div
                                                    data-mesh-id="comp-lh3d5tq3inlineContent-gridContainer"
                                                    data-testid="mesh-container-content"
                                                >
                                                    <section
                                                        id="comp-jxm15pfs"
                                                        className="comp-jxm15pfs CohWsy wixui-column-strip"
                                                    >
                                                        <div
                                                            id="bgLayers_comp-jxm15pfs"
                                                            data-hook="bgLayers"
                                                            data-motion-part="BG_LAYER"
                                                            className="if7Vw2"
                                                        >
                                                            <div
                                                                data-testid="colorUnderlay"
                                                                className="tcElKx i1tH8h"
                                                            ></div>
                                                            <div
                                                                id="bgMedia_comp-jxm15pfs"
                                                                data-motion-part="BG_MEDIA"
                                                                className="wG8dni"
                                                            ></div>
                                                        </div>
                                                        <div data-testid="columns" className="V5AUxf">
                                                            <div
                                                                id="comp-jxm15pgm"
                                                                className="comp-jxm15pgm YzqVVZ wixui-column-strip__column"
                                                            >
                                                                <div
                                                                    id="bgLayers_comp-jxm15pgm"
                                                                    data-hook="bgLayers"
                                                                    data-motion-part="BG_LAYER"
                                                                    className="MW5IWV"
                                                                >
                                                                    <div
                                                                        data-testid="colorUnderlay"
                                                                        className="LWbAav Kv1aVt"
                                                                    ></div>
                                                                    <div
                                                                        id="bgMedia_comp-jxm15pgm"
                                                                        data-motion-part="BG_MEDIA"
                                                                        className="VgO9Yg"
                                                                    ></div>
                                                                </div>
                                                                <div
                                                                    data-mesh-id="comp-jxm15pgminlineContent"
                                                                    data-testid="inline-content"
                                                                    className=""
                                                                >
                                                                    <div
                                                                        data-mesh-id="comp-jxm15pgminlineContent-gridContainer"
                                                                        data-testid="mesh-container-content"
                                                                    >
                                                                        <div
                                                                            id="comp-jzxg7pda"
                                                                            className="comp-jzxg7pda SPY_vo"
                                                                        >
                                                                            <div
                                                                                data-mesh-id="comp-jzxg7pdainlineContent"
                                                                                data-testid="inline-content"
                                                                                className=""
                                                                            >
                                                                                <div
                                                                                    data-mesh-id="comp-jzxg7pdainlineContent-gridContainer"
                                                                                    data-testid="mesh-container-content" data-aos="fade-up" data-aos-easing="ease-in-sine"
                                                                                >
                                                                                    <div
                                                                                        id="comp-jxm15pgq"
                                                                                        className="HcOXKn SxM0TO QxJLC3 lq2cno comp-jxm15pgq wixui-rich-text"
                                                                                        data-testid="richTextElement"
                                                                                    >
                                                                                        <h5
                                                                                            className="font_5 wixui-rich-text__text"
                                                                                            style={{fontSize: "35px"}}
                                                                                        >
                                          <span
                                              style={{color:"#313f4f"}}
                                              className="wixui-rich-text__text"
                                          ><span
                                              style={{  fontFamily: "libre baskerville,serif",
                                                fontWeight: "700",
                                                letterSpacing: "0.01em"
                                              }}
                                              className="wixui-rich-text__text"
                                          >How we invest</span
                                          ></span
                                          >
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div
                                                                                        id="comp-jxm1eyb7"
                                                                                        className="comp-jxm1eyb7 aVng1S wixui-horizontal-line"
                                                                                    ></div>
                                                                                    <div
                                                                                        id="comp-jxm15pgs"
                                                                                        className="HcOXKn SxM0TO QxJLC3 lq2cno comp-jxm15pgs wixui-rich-text"
                                                                                        data-testid="richTextElement"
                                                                                    >
                                                                                        <h5
                                                                                            className="font_5 wixui-rich-text__text"
                                                                                            style={{  fontSize: "18px", lineHeight: "1.4e"}}
                                                                                        >
                                                                                            Our diligence focuses on leadership, as founders are key to the company’s success. We seek companies building toward sustainability.
                                                                                        </h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            id="comp-jy07uw87"
                                                                            className="comp-jy07uw87 wixui-vector-image"
                                                                        >
                                                                            <div
                                                                                data-testid="svgRoot-comp-jy07uw87"
                                                                                className="AKxYR5 comp-jy07uw87"
                                                                                data-aos="fade-up"
                                                                                data-aos-easing="ease-in-sine"
                                                                                data-aos-delay="500"
                                                                            >

                                                                                <img src={OurProsessMainInest}
                                                                                     alt={""} style={{
                                                                                    width: '100%',
                                                                                    height: '100%'
                                                                                }}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </section>
                                        <section
                                            id="comp-lh3d5tq31"
                                            tabIndex="-1"
                                            className="Oqnisf comp-lh3d5tq31 wixui-section"
                                            data-block-level-container="classNameicSection"
                                        >
                                            <div
                                                id="bgLayers_comp-lh3d5tq31"
                                                data-hook="bgLayers"
                                                data-motion-part="BG_LAYER"
                                                className="MW5IWV"
                                            >
                                                <div
                                                    data-testid="colorUnderlay"
                                                    className="LWbAav Kv1aVt"
                                                ></div>
                                                <div
                                                    id="bgMedia_comp-lh3d5tq31"
                                                    data-motion-part="BG_MEDIA"
                                                    className="VgO9Yg"
                                                ></div>
                                            </div>
                                            <div
                                                data-mesh-id="comp-lh3d5tq31inlineContent"
                                                data-testid="inline-content"
                                                className=""
                                            >
                                                <div
                                                    data-mesh-id="comp-lh3d5tq31inlineContent-gridContainer"
                                                    data-testid="mesh-container-content"
                                                >
                                                    <section
                                                        id="comp-jxm333u5"
                                                        className="comp-jxm333u5 CohWsy wixui-column-strip"
                                                    >
                                                        <div
                                                            id="bgLayers_comp-jxm333u5"
                                                            data-hook="bgLayers"
                                                            data-motion-part="BG_LAYER"
                                                            className="if7Vw2"
                                                        >
                                                            <div
                                                                data-testid="colorUnderlay"
                                                                className="tcElKx i1tH8h"
                                                            ></div>
                                                            <div
                                                                id="bgMedia_comp-jxm333u5"
                                                                data-motion-part="BG_MEDIA"
                                                                className="wG8dni"
                                                            ></div>
                                                        </div>
                                                        <div data-testid="columns" className="V5AUxf">
                                                            <div
                                                                id="comp-jxm333yd"
                                                                className="comp-jxm333yd YzqVVZ wixui-column-strip__column"
                                                            >
                                                                <div
                                                                    id="bgLayers_comp-jxm333yd"
                                                                    data-hook="bgLayers"
                                                                    data-motion-part="BG_LAYER"
                                                                    className="MW5IWV"
                                                                >
                                                                    <div
                                                                        data-testid="colorUnderlay"
                                                                        className="LWbAav Kv1aVt"
                                                                    ></div>
                                                                    <div
                                                                        id="bgMedia_comp-jxm333yd"
                                                                        data-motion-part="BG_MEDIA"
                                                                        className="VgO9Yg"
                                                                    ></div>
                                                                </div>
                                                                <div
                                                                    data-mesh-id="comp-jxm333ydinlineContent"
                                                                    data-testid="inline-content"
                                                                    className=""
                                                                >
                                                                    <div
                                                                        data-mesh-id="comp-jxm333ydinlineContent-gridContainer"
                                                                        data-testid="mesh-container-content"
                                                                    >
                                                                        <div
                                                                            id="comp-jxmaiv0z"
                                                                            className="comp-jxmaiv0z wixui-vector-image"
                                                                        >
                                                                            <div
                                                                                data-testid="svgRoot-comp-jxmaiv0z"
                                                                                className="AKxYR5 VZYmYf comp-jxmaiv0z"
                                                                            >
                                                                                
                                                                               <img src={WhiteLoge} style={{width:'100%'}} alt={""}/>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            id="comp-jxmajf99"
                                                                            className="HcOXKn SxM0TO QxJLC3 lq2cno comp-jxmajf99 wixui-rich-text"
                                                                            data-testid="richTextElement"
                                                                        >
                                                                            <h5
                                                                                className="font_5 wixui-rich-text__text"
                                                                                style={{fontSize: "35px", textAlign: "center"}}
                                                                            >
                                    <span
                                        style={{  color: "rgb(255, 255, 255)",
                                        fontFamily: "libre baskerville, serif",
                                        fontWeight: "700",
                                        letterSpacing: "0.01em" }}
                                        className="wixui-rich-text__text"
                                    >Want to learn more?
                                    </span>
                                                                            </h5>

                                                                            <h5
                                                                                className="font_5 wixui-rich-text__text"
                                                                                style={{fontSize: "35px", textAlign: "center"}}
                                                                            >
                                    <span
                                        style={{  color: "rgb(255, 255, 255)",
                                        fontFamily: "libre baskerville, serif",
                                        fontWeight: "700",
                                        letterSpacing: "0.01em" }}
                                        className="wixui-rich-text__text"
                                    >Get in touch</span
                                    >
                                                                            </h5>
                                                                        </div>
                                                                        <div
                                                                            className="comp-jxmajfa0 FubTgk"
                                                                            id="comp-jxmajfa0"
                                                                            aria-disabled="false"
                                                                        >
                                                                            <a
                                                                                data-testid="linkElement"
                                                                                href="/contact"
                                                                                target="_self"
                                                                                className="uDW_Qe wixui-button PlZyDq"
                                                                                aria-disabled="false"
                                                                            ><span
                                                                                className="l7_2fn wixui-button__label"
                                                                            >Contact Us</span
                                                                            ></a
                                                                            >
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>


    );

}

export default OurProsessMain;