import footer_image from "../static/img/footer_imge_white.png";

function Footer() {
    const footerStyle = {
        backgroundColor: "#228b22", // Light green background
        color: "#fff",
        padding: "0px 10px",
        paddingTop: "10px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    };

    const linkStyle = {
        fontSize: "16px",
        color: "#fff",
        textDecoration: "none",
        margin: "10px 0", // Space between links for readability
        display: "block",
    };

    const footerImageStyle = {
        width: "80px", // Smaller size for mobile
        height: "auto",
        marginBottom: "15px",
    };

    const copyrightContainerStyle = {
        backgroundColor: "#1b6f1b", // Darker green for contrast with main footer
        width: "100%",
        padding: "20px 0",
        marginTop: "20px",
        marginBottom: "0px",
    };

    const copyrightTextStyle = {
        fontSize: "14px",
        color: "#fff",
        textAlign: "center",
    };

    return (
        <footer style={footerStyle} id="SITE_FOOTER">
            <div>
                <img src={footer_image} alt="Logo" style={footerImageStyle} />
            </div>
            <nav>
                <a href="/" style={linkStyle}>Home</a>
                <a href="/ourProcess" style={linkStyle}>Our Process</a>
                <a href="/about" style={linkStyle}>About</a>
                <a href="/contact" style={linkStyle}>Contact</a>
            </nav>
            <div style={copyrightContainerStyle}>
                <p style={copyrightTextStyle}>
                    © 2024 by VerdeGlobal.co.
                </p>
            </div>
        </footer>
    );
}

export default Footer;
