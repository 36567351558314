import footer_image from "../static/img/footer_imge_white.png";

function Footer() {
    return (
        <footer id="SITE_FOOTER" className="xU8fqS SITE_FOOTER wixui-footer" tabIndex="-1">
            <div className="_C0cVf">
                <div className="_4XcTfy" data-testid="screenWidthContainerBg"></div>
            </div>
            <div className="U4Bvut">
                <div className="G5K6X8">
                    <div className="gUbusX" data-testid="screenWidthContainerBgCenter"></div>
                </div>
                <div className="CJF7A2">
                    <div
                        data-mesh-id="SITE_FOOTERinlineContent"
                        data-testid="inline-content"
                        className=""
                    >
                        <div
                            data-mesh-id="SITE_FOOTERinlineContent-gridContainer"
                            data-testid="mesh-container-content"
                        >
                            <div id="comp-jxgb2sr3" className="comp-jxgb2sr3 wixui-vector-image">
                                <div
                                    data-testid="svgRoot-comp-jxgb2sr3"
                                    className="AKxYR5 VZYmYf comp-jxgb2sr3"
                                >

                                    <img src={footer_image} alt={""} style={{width:'100%',height:'100%'}}/>
                                </div>
                            </div>
                            <div
                                id="comp-jxm1t9ot"
                                className="HcOXKn SxM0TO QxJLC3 lq2cno comp-jxm1t9ot wixui-rich-text"
                                data-testid="richTextElement"
                            >
                                <h6 className="font_6 wixui-rich-text__text" style={{fontSize: '20px'}}>
                                    <a
                                        href="/"
                                        target="_self"
                                        className="wixui-rich-text__text"
                                    ><span style={{fontWeight: 'bold'}} className="wixui-rich-text__text"
                                    ><span style={{fontSize: '20px'}} className="wixui-rich-text__text"
                                    ><span
                                        style={{fontFamily: 'proxima-n-w01-reg, sans-serif'}}
                                        className="wixui-rich-text__text"
                                    ><span
                                        style={{letterSpacing: '0.03em'}}
                                        className="wixui-rich-text__text"
                                    ><span className="color_11 wixui-rich-text__text"
                                    ><span
                                        style={{textDecoration: 'underline'}}
                                        className="wixui-rich-text__text"
                                    >Home</span
                                    ></span
                                    ></span
                                    ></span
                                    ></span
                                    ></span
                                    ></a
                                    >
                                </h6>
                            </div>
                            <div
                                id="comp-jxm1pdj1"
                                className="HcOXKn SxM0TO QxJLC3 lq2cno comp-jxm1pdj1 wixui-rich-text"
                                data-testid="richTextElement"
                            >
                                <h6 className="font_6 wixui-rich-text__text" style={{fontSize: '20px'}}>
                                    <a
                                        href="/ourProcess"
                                        target="_self"
                                        className="wixui-rich-text__text"
                                    ><span style={{fontWeight: 'bold'}} className="wixui-rich-text__text"
                                    ><span style={{fontSize: '20px'}} className="wixui-rich-text__text"
                                    ><span
                                        style={{fontFamily: 'proxima-n-w01-reg, sans-serif'}}
                                        className="wixui-rich-text__text"
                                    ><span
                                        style={{letterSpacing: '0.03em'}}
                                        className="wixui-rich-text__text"
                                    ><span className="color_11 wixui-rich-text__text"
                                    ><span
                                        style={{textDecoration: 'underline'}}
                                        className="wixui-rich-text__text"
                                    >Our Process</span
                                    ></span
                                    ></span
                                    ></span
                                    ></span
                                    ></span
                                    ></a
                                    >
                                </h6>
                            </div>
                            <div
                                id="comp-isykdffh"
                                className="HcOXKn SxM0TO QxJLC3 lq2cno comp-isykdffh wixui-rich-text"
                                data-testid="richTextElement"
                            >
                                <h6 className="font_6 wixui-rich-text__text" style={{fontSize: '20px'}}>
                                    <a
                                        href="/about"
                                        target="_self"
                                        className="wixui-rich-text__text"
                                    ><span style={{fontWeight: 'bold'}} className="wixui-rich-text__text"
                                    ><span style={{fontSize: '20px'}} className="wixui-rich-text__text"
                                    ><span
                                        style={{fontFamily: 'proxima-n-w01-reg, sans-serif'}}
                                        className="wixui-rich-text__text"
                                    ><span
                                        style={{letterSpacing: '0.03em'}}
                                        className="wixui-rich-text__text"
                                    ><span className="color_11 wixui-rich-text__text"
                                    ><span
                                        style={{textDecoration: 'underline'}}
                                        className="wixui-rich-text__text"
                                    >About</span
                                    ></span
                                    ></span
                                    ></span
                                    ></span
                                    ></span
                                    ></a
                                    >
                                </h6>
                            </div>
                            <div
                                id="comp-jxm1p3a6"
                                className="HcOXKn SxM0TO QxJLC3 lq2cno comp-jxm1p3a6 wixui-rich-text"
                                data-testid="richTextElement"
                            >
                                <h6 className="font_6 wixui-rich-text__text" style={{fontSize: '20px'}}>
                                    <a
                                        href="/contact"
                                        target="_self"
                                        className="wixui-rich-text__text"
                                    ><span style={{fontWeight: 'bold'}} className="wixui-rich-text__text"
                                    ><span style={{fontSize: '20px'}} className="wixui-rich-text__text"
                                    ><span
                                        style={{fontFamily: 'proxima-n-w01-reg, sans-serif'}}
                                        className="wixui-rich-text__text"
                                    ><span
                                        style={{letterSpacing: '0.03em'}}
                                        className="wixui-rich-text__text"
                                    ><span className="color_11 wixui-rich-text__text"
                                    ><span
                                        style={{textDecoration: 'underline'}}
                                        className="wixui-rich-text__text"
                                    >Contact</span
                                    ></span
                                    ></span
                                    ></span
                                    ></span
                                    ></span
                                    ></a
                                    >
                                </h6>
                            </div>


                            <section
                                id="comp-isyk3ta2"
                                className="comp-isyk3ta2 CohWsy wixui-column-strip"
                            >

                                <div data-testid="columns" className="V5AUxf">
                                    <div
                                        id="comp-isyk3tlw"
                                        className="comp-isyk3tlw YzqVVZ wixui-column-strip__column"
                                    >
                                        <div
                                            id="bgLayers_comp-isyk3tlw"
                                            data-hook="bgLayers"
                                            data-motion-part="BG_LAYER"
                                            className="MW5IWV"
                                        >
                                            <div data-testid="colorUnderlay" className="LWbAav Kv1aVt"></div>
                                            <div
                                                id="bgMedia_comp-isyk3tlw"
                                                data-motion-part="BG_MEDIA"
                                                className="VgO9Yg"
                                            ></div>
                                        </div>
                                        <div
                                            data-mesh-id="comp-isyk3tlwinlineContent"
                                            data-testid="inline-content"
                                            className=""
                                        >
                                            <div
                                                data-mesh-id="comp-isyk3tlwinlineContent-gridContainer"
                                                data-testid="mesh-container-content"
                                            >
                                                <div
                                                    id="comp-isykf1rb"
                                                    className="HcOXKn SxM0TO QxJLC3 lq2cno comp-isykf1rb wixui-rich-text"
                                                    data-testid="richTextElement"
                                                >
                                                    <p
                                                        className="font_9 wixui-rich-text__text"
                                                        style={{textAlign: 'center', fontSize: '16px'}}
                                                    >
                        <span className="color_11 wixui-rich-text__text"
                        >© 2024 by  VerdeGlobal.co.</span
                        >
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    );
}

export default Footer;
