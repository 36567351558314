import React, { useState } from 'react';

function ContactMain() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        companyName: '',
        cityState: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const generateMailtoLink = () => {
        const mailtoLink = `mailto:Info@verdeglobal.co?subject=${encodeURIComponent(formData.subject)}&body=Name: ${encodeURIComponent(formData.name)}%0D%0AEmail: ${encodeURIComponent(formData.email)}%0D%0ACompany Name: ${encodeURIComponent(formData.companyName)}%0D%0ACity, State: ${encodeURIComponent(formData.cityState)}%0D%0A%0D%0AMessage: ${encodeURIComponent(formData.message)}`;
        return mailtoLink;
    };

    const mainStyle = {
        padding: '20px',
        maxWidth: '480px',
        margin: '0 auto',
        boxSizing: 'border-box',
        backgroundColor: '#D1C094',
        paddingTop: '30%',
        paddingBottom: '1' +
            '0%',
    };

    const formTitleStyle = {
        fontSize: '28px',
        lineHeight: '1.4em',
        textAlign: 'center',
        color: '#313F4F',
        fontFamily: 'libre baskerville, serif',
        fontWeight: 'bold',
        marginBottom: '20px'
    };

    const formDescriptionStyle = {
        fontSize: '16px',
        lineHeight: '1.4em',
        textAlign: 'center',
        color: '#313F4F',
        fontFamily: 'proxima-n-w01-reg, sans-serif',
        marginBottom: '30px'
    };

    const inputStyle = {
        width: '100%',
        padding: '10px',
        marginBottom: '15px',
        fontSize: '16px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        boxSizing: 'border-box'
    };

    const textareaStyle = {
        width: '100%',
        padding: '10px',
        marginBottom: '15px',
        fontSize: '16px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
        resize: 'vertical'
    };

    const buttonStyle = {
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        backgroundColor: '#4C5C68',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        textAlign: 'center',
        boxSizing: 'border-box',
        marginTop: '15px',
        textDecoration: 'none',  // Remove underline for link appearance
        display: 'inline-block',  // Ensures button-like appearance
    };

    const thankYouMessageStyle = {
        fontSize: '14px',
        textAlign: 'center',
        color: '#313F4F',
        fontFamily: 'helvetica, sans-serif',
        marginTop: '20px',
    };

    return (
        <main style={mainStyle}>
            <h4 style={formTitleStyle}>Contact Us</h4>
            <p style={formDescriptionStyle}>
                For more information please submit the form below.
            </p>
            <form>
                <input
                    type="text"
                    name="name"
                    placeholder="Name *"
                    value={formData.name}
                    onChange={handleChange}
                    style={inputStyle}
                    required
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Email *"
                    value={formData.email}
                    onChange={handleChange}
                    style={inputStyle}
                    required
                />
                <input
                    type="text"
                    name="companyName"
                    placeholder="Company Name"
                    value={formData.companyName}
                    onChange={handleChange}
                    style={inputStyle}
                />
                <input
                    type="text"
                    name="cityState"
                    placeholder="City, State"
                    value={formData.cityState}
                    onChange={handleChange}
                    style={inputStyle}
                />
                <input
                    type="text"
                    name="subject"
                    placeholder="Subject *"
                    value={formData.subject}
                    onChange={handleChange}
                    style={inputStyle}
                    required
                />
                <textarea
                    name="message"
                    placeholder="Message *"
                    value={formData.message}
                    onChange={handleChange}
                    style={textareaStyle}
                    rows="5"
                    required
                ></textarea>
                <a
                    href={generateMailtoLink()}
                    style={buttonStyle}
                >
                    Send
                </a>
                <p style={thankYouMessageStyle}>
                    Thank you for submitting your inquiry. We will try our best to respond within 72 hours if you are a good fit.
                </p>
            </form>
        </main>
    );
}

export default ContactMain;
