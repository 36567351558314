import WhiteLogo from '../static/img/White Logo.png'
function AboutMain() {



    return (

        <main id="PAGES_CONTAINER" className="PAGES_CONTAINER" tabIndex="-1" data-main-content="true">
            <div id="SITE_PAGES" className="JsJXaX SITE_PAGES">
                <div id="SITE_PAGES_TRANSITION_GROUP" className="AnQkDU">
                    <div id="bzld2" className="dBAkHi bzld2">
                        <div className="PFkO7r wixui-page" data-testid="page-bg"></div>
                        <div className="HT5ybB">
                            <div id="Containerbzld2" className="Containerbzld2 SPY_vo">
                                <div data-mesh-id="Containerbzld2inlineContent" data-testid="inline-content" className="">
                                    <div data-mesh-id="Containerbzld2inlineContent-gridContainer"
                                         data-testid="mesh-container-content">
                                        <section id="comp-lh3d5tpq" tabIndex="-1" className="Oqnisf comp-lh3d5tpq wixui-section"
                                                 data-block-level-container="ClassicSection">
                                            <div id="bgLayers_comp-lh3d5tpq" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                                 className="MW5IWV">
                                                <div data-testid="colorUnderlay" className="LWbAav Kv1aVt"></div>
                                                <div id="bgMedia_comp-lh3d5tpq" data-motion-part="BG_MEDIA" className="VgO9Yg">
                                                </div>
                                            </div>
                                            <div data-mesh-id="comp-lh3d5tpqinlineContent" data-testid="inline-content"
                                                 className="">
                                                <div data-mesh-id="comp-lh3d5tpqinlineContent-gridContainer"
                                                     data-testid="mesh-container-content">
                                                    <section id="comp-jxmbaamv"
                                                             className="comp-jxmbaamv CohWsy wixui-column-strip">
                                                        <div id="bgLayers_comp-jxmbaamv" data-hook="bgLayers"
                                                             data-motion-part="BG_LAYER" className="if7Vw2">
                                                            <div data-testid="colorUnderlay" className="tcElKx i1tH8h"></div>
                                                            <div id="bgMedia_comp-jxmbaamv" data-motion-part="BG_MEDIA"
                                                                 className="wG8dni"></div>
                                                        </div>
                                                        <div data-testid="columns" className="V5AUxf">
                                                            <div id="comp-jxmbaaov"
                                                                 className="comp-jxmbaaov YzqVVZ wixui-column-strip__column">
                                                                <div id="bgLayers_comp-jxmbaaov" data-hook="bgLayers"
                                                                     data-motion-part="BG_LAYER" className="MW5IWV">
                                                                    <div data-testid="colorUnderlay" className="LWbAav Kv1aVt">
                                                                    </div>
                                                                    <div id="bgMedia_comp-jxmbaaov" data-motion-part="BG_MEDIA"
                                                                         className="VgO9Yg"></div>
                                                                </div>
                                                                <div data-mesh-id="comp-jxmbaaovinlineContent"
                                                                     data-testid="inline-content" className="">
                                                                    <div data-mesh-id="comp-jxmbaaovinlineContent-gridContainer"
                                                                         data-testid="mesh-container-content">
                                                                        <div id="comp-jxmbalwe"  data-aos="fade-up" data-aos-easing="ease-in-sine"
                                                                             className="HcOXKn c9GqVL QxJLC3 lq2cno comp-jxmbalwe wixui-rich-text"
                                                                             data-testid="richTextElement"
                                                                             data-motion-enter="done">
                                                                            <h4 className="font_4 wixui-rich-text__text"
                                                                                style={{fontSize: '40px' , lineHeight: '1.4em'
                                                                                }}>
                                                                        <span style={{letterSpacing: 'normal' }}
                                                                              className="wixui-rich-text__text"><span
                                                                            style={{color: '#ffffff' }}
                                                                            className="wixui-rich-text__text"><span
                                                                            style={{fontSize: '40px' }}
                                                                            className="wixui-rich-text__text"><span
                                                                            style={{fontWeight: 'bold' }}
                                                                            className="wixui-rich-text__text"><span
                                                                            style={{fontFamily: 'libre baskerville, serif'
                                                                            }}
                                                                            className="wixui-rich-text__text">Surrounding
                                                                                            ourselves with innovators
                                                                                            and challengers is key to
                                                                                            Verde Global's philosophy
                                                                                            and success.
                                                                                        </span></span></span></span></span>
                                                                            </h4>
                                                                        </div>
                                                                        <div id="comp-jxmc9hwq"
                                                                             className="comp-jxmc9hwq wixui-vector-image">
                                                                            <div data-testid="svgRoot-comp-jxmc9hwq"
                                                                                 className="AKxYR5 VZYmYf comp-jxmc9hwq">
                                                                                <img src={WhiteLogo} style={{width:'100%'}} alt={""} />


                                                                            </div>
                                                                        </div>
                                                                        <div id="comp-jxmbalxa" data-aos="fade-up" data-aos-easing="ease-in-sine"
                                                                             className="comp-jxmbalxa aVng1S wixui-horizontal-line"
                                                                             data-motion-enter="done"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </section>
                                        <section id="comp-lh3d5tpr" tabIndex="-1" className="Oqnisf comp-lh3d5tpr wixui-section"
                                                 data-block-level-container="ClassicSection">
                                            <div id="bgLayers_comp-lh3d5tpr" data-hook="bgLayers" data-motion-part="BG_LAYER"
                                                 className="MW5IWV">
                                                <div data-testid="colorUnderlay" className="LWbAav Kv1aVt"></div>
                                                <div id="bgMedia_comp-lh3d5tpr" data-motion-part="BG_MEDIA" className="VgO9Yg">
                                                </div>
                                            </div>
                                            <div data-mesh-id="comp-lh3d5tprinlineContent" data-testid="inline-content"
                                                 className="">
                                                <div data-mesh-id="comp-lh3d5tprinlineContent-gridContainer"
                                                     data-testid="mesh-container-content">
                                                    <section id="comp-jy05446j"
                                                             className="comp-jy05446j CohWsy wixui-column-strip">
                                                        <div id="bgLayers_comp-jy05446j" data-hook="bgLayers"
                                                             data-motion-part="BG_LAYER" className="if7Vw2">
                                                            <div data-testid="colorUnderlay" className="tcElKx i1tH8h"></div>
                                                            <div id="bgMedia_comp-jy05446j" data-motion-part="BG_MEDIA"
                                                                 className="wG8dni"></div>
                                                        </div>
                                                        <div data-testid="columns" className="V5AUxf">
                                                            <div id="comp-jy05447e"
                                                                 className="comp-jy05447e YzqVVZ wixui-column-strip__column">
                                                                <div id="bgLayers_comp-jy05447e" data-hook="bgLayers"
                                                                     data-motion-part="BG_LAYER" className="MW5IWV">
                                                                    <div data-testid="colorUnderlay" className="LWbAav Kv1aVt">
                                                                    </div>
                                                                    <div id="bgMedia_comp-jy05447e" data-motion-part="BG_MEDIA"
                                                                         className="VgO9Yg"></div>
                                                                </div>
                                                                <div data-mesh-id="comp-jy05447einlineContent"
                                                                     data-testid="inline-content" className="">
                                                                    <div data-mesh-id="comp-jy05447einlineContent-gridContainer"
                                                                         data-testid="mesh-container-content">
                                                                        <div id="comp-jzxgaoq8" className="comp-jzxgaoq8 SPY_vo"
                                                                             data-motion-enter="done">
                                                                            <div data-mesh-id="comp-jzxgaoq8inlineContent"
                                                                                 data-testid="inline-content" className="">
                                                                                <div data-mesh-id="comp-jzxgaoq8inlineContent-gridContainer" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="500"
                                                                                     data-testid="mesh-container-content">
                                                                                    <div id="comp-jy05447i"
                                                                                         className="HcOXKn SxM0TO QxJLC3 lq2cno comp-jy05447i wixui-rich-text"
                                                                                         data-testid="richTextElement">
                                                                                        <h5 className="font_5 wixui-rich-text__text"
                                                                                            style={{fontSize: '35px' }}>
                                                                                    <span style={{color: '#313f4f' }}
                                                                                          className="wixui-rich-text__text"><span
                                                                                        style={{fontFamily: 'libre baskerville, serif'
                                                                                            , fontWeight: '700' ,
                                                                                            letterSpacing: '0.01em' }}
                                                                                        className="wixui-rich-text__text">We’ve
                                                                                            assembled a team of
                                                                                            sustainability-focused
                                                                                            entrepreneurs and advisors
                                                                                            to support our
                                                                                            mission.</span></span>
                                                                                        </h5>
                                                                                    </div>
                                                                                    <div id="comp-jy05447o"
                                                                                         className="HcOXKn c9GqVL QxJLC3 lq2cno comp-jy05447o wixui-rich-text"
                                                                                         data-testid="richTextElement">
                                                                                        <h5 className="font_5 wixui-rich-text__text"
                                                                                            style={{fontSize: '22px' ,
                                                                                                lineHeight: '1.4em' }}>
                                                                                    <span
                                                                                        style={{letterSpacing: 'normal'
                                                                                        }}
                                                                                        className="wixui-rich-text__text"><span
                                                                                        style={{fontSize: '22px' }}
                                                                                        className="wixui-rich-text__text">We
                                                                                            engage our investors at
                                                                                            every stage, from initial
                                                                                            screening to post-investment
                                                                                            growth, ensuring we remain a
                                                                                            valuable
                                                                                            asset.</span></span>
                                                                                        </h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );

}

export default AboutMain;