import Header from "../sections/Header";
import HomeMain from "../sections/HomeMain";
import Footer from "../sections/Footer";
import '../static/css/Home.css';


function Home() {

    return (

        <div id="site-root" className="site-root">
            <div id="masterPage" className="mesh-layout remove-wrappers masterPage css-editing-scope">
                <Header/>
                <HomeMain/>
                <Footer/>
            </div>
        </div>

    );
}

export default Home;
