import React, { useState, useEffect } from 'react';
import nav_img from '../static/img/nav_image.png';
import { useLocation } from 'react-router-dom';

function Header() {
    const location = useLocation();
    const [, setScrolled] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false); // Mobile menu state

    // Function to get data-state based on the current path
    const getDataState = (path) => {

        return location.pathname === path ? 'menu selected link' : 'menu false link';
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScroll = window.pageYOffset;
            if (currentScroll > 100) {
                setScrolled(currentScroll - lastScrollTop > 10); // Scrolling down
            } else {
                setScrolled(false);
            }
            setLastScrollTop(currentScroll);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollTop]);

    // Toggle mobile menu visibility
    const toggleMenu = () => setMenuOpen(!menuOpen);

    // Enforce all styles as "important" using useEffect
    useEffect(() => {
        const headerElement = document.querySelector("header");
        if (headerElement) {
            headerElement.style.setProperty("position", "fixed", "important");
            headerElement.style.setProperty("width", "100%", "important");
            headerElement.style.setProperty("top", "0", "important");
            headerElement.style.setProperty("background-color", "#fffff0", "important");
            headerElement.style.setProperty("color", "#000", "important"); // Adjusted color to match light background
            headerElement.style.setProperty("display", "flex", "important");
            headerElement.style.setProperty("justify-content", "space-between", "important");
            headerElement.style.setProperty("align-items", "center", "important");
            headerElement.style.setProperty("padding", "10px 20px", "important");
            headerElement.style.setProperty("z-index", "1000", "important");
        }
    }, []);

    const menuStyle = {
        display: menuOpen ? "block" : "none",
        position: "absolute",
        top: "100%",
        left: 0,
        width: "100%",
        backgroundColor: "#fffff0", // Match header color
        padding: "10px 0",
        textAlign: "center", // Center align text
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add slight shadow for separation
    };

    const menuItemStyle = {
        padding: "10px 0", // Adjusted padding to center text vertically
        color: "#000", // Dark color for readability on light background
        textDecoration: "none",
        display: "block",
        fontSize:"15px",
    };


    return (
        <header style={{height:"70px"}}>
            <div>
                <img src={nav_img} alt="Logo" style={{ height: "50px" }} />
            </div>
            <button onClick={toggleMenu} aria-label="Toggle menu"
                    style={{ fontSize: "24px", background: "none", border: "none", color: "#000", marginRight: "20px" }}>
                ☰
            </button>
            <nav style={menuStyle}>
                <a href="/" style={menuItemStyle} data-state={getDataState('/')}>Home</a>
                <a href="/ourProcess" style={menuItemStyle} data-state={getDataState('/ourProcess')}>Our Process</a>
                <a href="/about" style={menuItemStyle} data-state={getDataState('/about')}>About</a>
                <a href="/contact" style={menuItemStyle} data-state={getDataState('/contact')}>Contact</a>
            </nav>
        </header>
    );
}

export default Header;
